import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./PassportRecordRequirementItem.module.css";

import PassportRequirementReviewerView from "components/PassportRequirementReviewerView";
import { Modal } from "@cpnw/ui";

import { LinkItem } from "@cpnw/ui";

import { ContactTypes } from "pages/enums";
import handleIconSet from "components/_Helpers/HandleIconSet";

export default function PassportRecordRequirementItem({ item, onClick }) {
  return (
    <>
      <div className={styles.record_row}>
        <div className={classNames(styles.record_item, styles.req_name)}>
          {onClick ? (
            <div onClick={(ev) => {
              if (item.canView) {
                ev.preventDefault()
                ev.stopPropagation()
                onClick(item)
              }
            }}>
              <LinkItem label={item.reqName} src="#" disabled={item.canView == false} linkClassName={item.canReview == false ? styles.red : null} />{" "}
            </div>
          ) : (
            <LinkItem label={item.reqName} src={item.src} />
          )}
        </div>
        <div className={classNames(styles.record_item, styles.icon)}>
          {/* {item.status} */}
          <div className={styles.content}>{handleIconSet(item.status)}</div>
        </div>
        <div className={classNames(styles.record_item)}>
          <p className={styles.content}>{item.expiration}</p>
        </div>
        <div className={classNames(styles.record_item, styles.dueDate)}>
          <p className={styles.content}>{item.dueDate}</p>
        </div>
        <div className={classNames(styles.record_item)}>
          <p className={styles.content}>{item.score}</p>
        </div>
        <div className={classNames(styles.record_item)}>
          <p className={styles.content}>{item.type}</p>
        </div>
        <div className={classNames(styles.record_item)}>
          <p className={styles.content}>{item.freq}</p>
        </div>
        <div className={classNames(styles.record_item, styles.category)}>
          <p className={styles.content}>{item.createdByTypeContact}</p>
        </div>
        <div className={classNames(styles.record_item)}>
          <p className={styles.content}>{item.reviewer}</p>
        </div>
      </div>
    </>
  );
}

PassportRecordRequirementItem.propTypes = {
  item: PropTypes.shape({
    reqName: PropTypes.string.isRequired,
    src: PropTypes.string,
    freq: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    score: PropTypes.number,
    expiration: PropTypes.string.isRequired,
    reviewer: PropTypes.string.isRequired,
    createdByTypeContact: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
};
