import React from "react";
import PropTypes from "prop-types";
import styles from "./DashboardClinicalAssignmentItem.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faBan,
} from "@fortawesome/free-solid-svg-icons";

const DashboardClinicalAssignmentItem = ({ assignment, columnWidths }) => {
  const { location, startDate, endDate, status } = assignment;
  const getIcon = (status) => {
    switch (status) {
      case 0: // Approve
        return (
          <FontAwesomeIcon
            icon={faCheck}
            style={{
              color: "#007e8d",
              fontSize: "20px",
              marginTop: "3px",
              marginRight: "8px",
            }}
          />
        );
      case 1: // Reject
        return (
          <FontAwesomeIcon
            icon={faBan}
            style={{
              color: "red",
              fontSize: "16px",
              rotate: "90deg",
              marginTop: "3px",
              marginRight: "8px",
            }}
          />
        );
      case 2: // Needs Review
        return "";
      default:
        return "";
    }
  };
  return (
    <div className={styles.assignmentItem}>
      <div className={styles.cell} style={{ width: columnWidths[0] }}>
        {location}
      </div>
      <div className={styles.cell} style={{ width: columnWidths[1] }}>
        {startDate}
      </div>
      <div className={styles.cell} style={{ width: columnWidths[2] }}>
        {endDate}
      </div>
      <div className={styles.cell} style={{ width: columnWidths[3] , textAlign: "center"}}>
        {getIcon(status)}
      </div>
    </div>
  );
};

DashboardClinicalAssignmentItem.propTypes = {
  assignment: PropTypes.shape({
    location: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  columnWidths: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default DashboardClinicalAssignmentItem;
