import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import {
  faCheck,
  faBan,
  faStarOfLife,
  faHourglassStart,
  faHourglassHalf,
  faExclamation
} from "@fortawesome/free-solid-svg-icons";

const handleIconSet = (stat) => {
    let statusToLower = stat.toLowerCase();

    if (statusToLower === "approved") {
      return (
        <FontAwesomeIcon
          icon={faCheck}
          style={{ color: "#007e8d", fontSize: "18px", marginTop: "6px" }}
        />
      );
    } else if (statusToLower === "conditionally approved") {
      return (
        <FontAwesomeIcon
          icon={faCheck}
          style={{ color: "#f8a607", fontSize: "18px", marginTop: "6px" }}
        />
      );
    } else if (statusToLower === "rejected") {
      return (
        <FontAwesomeIcon
          icon={faBan}
          style={{ color: "red", fontSize: "16px", rotate: "90deg" }}
        />
      );
    } else if (statusToLower === "waiver") {
      return (
        <FontAwesomeIcon
          icon={faStarOfLife}
          style={{ color: "#aa9b73", fontSize: "16px" }}
        />
      );
    } else if (statusToLower === "expired") {
      return (
        <FontAwesomeIcon
          icon={faHourglassStart}
          style={{ color: "red", fontSize: "16px", rotate: "180deg" }}
        />
      );
    } else if (statusToLower === "expiring") {
      return (
        <FontAwesomeIcon
          icon={faExclamation}
          style={{ color: "#000000", fontSize: "16px" }}
        />
      );
    }
    else {
      return (
        <div>{stat}</div>
      );
    }
  };


  export default handleIconSet;