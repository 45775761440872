import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./PassportRecordRequirementTable.module.css";
import DashboardSortTableHeader from "components/DashboardSortTableHeader";
import PassportRecordRequirementItem from "components/PassportRecordRequirementItem";
import { DashboardItemsPerPage } from "components/DashboardItemsPerPage";
import DashboardSearch from "components/DashboardSearch";
import DashboardTablePagination from "components/DashboardTablePagination";
import { Loading } from "@cpnw/ui";

export default function PassportRecordRequirementTable({
  items,
  onItemClick,
  isLoading,
}) {
  // console.log(items);
  //sort header directions
  const [reqNameSortDir, setReqNameSortDir] = useState("");
  const [freqSortDir, setFreqSortDir] = useState("");
  const [typeSortDir, setTypeSortDir] = useState("");
  const [statSortDir, setStatSortDir] = useState("");
  const [dueSortDir, setDueSortDir] = useState("");
  const [expirSortDir, setExpirSortDir] = useState("");
  const [revSortDir, setRevSortDir] = useState("");
  const [scoreSortDir, setScoreSortDir] = useState("");
  const [categorySortDir, setCategorySortDir] = useState("");

  //items per page limit and options
  const [limit, setLimit] = useState(10);
  const [limitOptions] = useState([5, 10, 20, 50, 100, 1000]);

  //search state, and minimum characters
  const [searchValue, setSearchValue] = useState("");
  const [minChar] = useState(2);

  //current page for pagiation
  const [currentPage, setCurrentPage] = useState(1);

  //items after sorting, array of key value pairs for sort directions
  const [sortedItems, setSortedItems] = useState(items);
  const [sortArrayValues, setSortArrayValues] = useState([]);

  useEffect(() => {
    setSortedItems(items);
  }, [items]);

  const handleLimitChange = (value) => {
    setLimit(value);
  };

  const handleSearch = (value) => {
    //if value is not ''
    if (value.length >= minChar) {
      setSearchValue(value);
      //call search function passing the original items passed to search from
      preformSearch(items, value);
      //reset the current pagiation page
      setCurrentPage(1);
    } else {
      //reset the sorted items to items passed
      setSortedItems(items);
      //reset the current pagiation pate
      setCurrentPage(1);
    }

    // Reset sort directions and sort array values
    //allows for the headers to be reset upon searching
    setReqNameSortDir("");
    setFreqSortDir("");
    setTypeSortDir("");
    setStatSortDir("");
    setDueSortDir("");
    setExpirSortDir("");
    setRevSortDir("");
    setScoreSortDir("");
    setSortArrayValues([]);
    setCategorySortDir("");
  };

  //search function
  const preformSearch = (itemsArray, searchChar) => {
    console.log("itemsArray", itemsArray)
    console.log("searchChar", searchChar)

    let results = itemsArray.filter((item) =>
      Object.values(item).some((value) =>
        value !== null && value.toString().toLowerCase().includes(searchChar.toLowerCase())
      )
    );

    setSortedItems(results);
  };

  //#region sort directions
  const handleReqNameSortDir = (value) => {
    setReqNameSortDir(value);
    handleSortChange("reqName", value, handleSortItems);
  };
  const handleFreqSortDir = (value) => {
    setFreqSortDir(value);
    handleSortChange("freq", value, handleSortItems);
  };
  const handleTypeSortDir = (value) => {
    setTypeSortDir(value);
    handleSortChange("type", value, handleSortItems);
  };
  const handleStatSortDir = (value) => {
    setStatSortDir(value);
    handleSortChange("status", value, handleSortItems);
  };
  const handleDueSortDir = (value) => {
    setDueSortDir(value);
    handleSortChange("status", value, handleSortItems);
  };
  const handleExpirSortDir = (value) => {
    setExpirSortDir(value);
    handleSortChange("expiration", value, handleSortItems);
  };
  const handleRevSortDir = (value) => {
    setRevSortDir(value);
    handleSortChange("reviewer", value, handleSortItems);
  };
  const handleScoreSortDir = (value) => {
    setRevSortDir(value);
    handleSortChange("score", value, handleScoreSortDir);
  };
  const handleCategorySortDir = (value) => {
    setCategorySortDir(value);
    handleSortChange("createdByTypeContact", value, handleSortItems);
  };

  const handleSortChange = (header, direction, callback) => {
    setSortArrayValues((prevSortArray) => {
      let newSortArray;
      const existingSort = prevSortArray.find((sort) => sort.header === header);
      if (existingSort) {
        newSortArray = prevSortArray.map((sort) =>
          sort.header === header ? { ...sort, direction } : sort
        );
      } else {
        newSortArray = [...prevSortArray, { header, direction }];
      }
      // Callback to ensure this updates state immediately
      if (callback) {
        callback(newSortArray);
      }
      // Return the new sorted array
      return newSortArray;
    });
  };

  const handleSortItems = (sortArrayValues) => {
    const itemsToSort = [...sortedItems];

    itemsToSort.sort((a, b) => {
      for (let sort of sortArrayValues) {
        let { header, direction } = sort;

        let comparison = a[header].localeCompare(b[header]);
        if (direction === "asc" && comparison !== 0) {
          return comparison;
        }
        if (direction === "desc" && comparison !== 0) {
          return -comparison;
        }
      }
      return 0;
    });

    setSortedItems(itemsToSort);
  };
  //#endregion end sort directions

  //items to render based on search, sort, pagination, and limit
  let itemsToDisplay = sortedItems.slice(
    (currentPage - 1) * limit,
    currentPage * limit
  );

  return (
    <>
      <div className={styles.search_ipp}>
        <div>
          <DashboardItemsPerPage
            disabled={false}
            limit={limit}
            limitOptions={limitOptions}
            onLimitChange={handleLimitChange}
          />
        </div>
        <div className={styles.search}>
          <DashboardSearch
            onInput={(value) => handleSearch(value)}
            searchDelay={1000}
            minChar={minChar}
          />
        </div>
      </div>
      <div className={styles.table_container}>
        <div className={styles.table_headers}>
          <div className={styles.header}>
            <DashboardSortTableHeader
              //key here forces the component to re-render (fixes bug where the user cannot
              // click the same button, after searching.) e.g. (sort, then search, then click same
              // sort buttons)
              key={`reqName-${reqNameSortDir}`}
              title={"Requirement Name"}
              value={reqNameSortDir}
              onChange={handleReqNameSortDir}
              width={300}
            />
          </div>
          <div className={classNames(styles.header, styles.status_header)}>
            <DashboardSortTableHeader
              key={`status-${statSortDir}`}
              title={"Status"}
              value={statSortDir}
              onChange={handleStatSortDir}
              width={150}
            />
          </div>
          <div className={styles.header}>
            <DashboardSortTableHeader
              key={`expir-${expirSortDir}`}
              title={"Expiration"}
              value={expirSortDir}
              onChange={handleExpirSortDir}
              width={200}
            />
          </div>
          <div className={classNames(styles.header, styles.status_header)}>
            <DashboardSortTableHeader
              key={`due-${dueSortDir}`}
              title={"Due Date"}
              value={dueSortDir}
              onChange={handleDueSortDir}
              width={200}
            />
          </div>
          <div className={classNames(styles.header, styles.status_header)}>
            <DashboardSortTableHeader
              key={`score-${scoreSortDir}`}
              title={"Score"}
              value={scoreSortDir}
              onChange={handleScoreSortDir}
              width={200}
            />
          </div>
          <div className={styles.header}>
            <DashboardSortTableHeader
              key={`type-${typeSortDir}`}
              title={"Type"}
              value={typeSortDir}
              onChange={handleTypeSortDir}
              width={200}
            />
          </div>
          <div className={styles.header}>
            <DashboardSortTableHeader
              key={`freq-${freqSortDir}`}
              title={"Frequency"}
              value={freqSortDir}
              onChange={handleFreqSortDir}
              width={200}
            />
          </div>
          <div className={styles.header}>
            <DashboardSortTableHeader
              key={`category-${categorySortDir}`}
              title={"Category"}
              value={categorySortDir}
              onChange={handleCategorySortDir}
              width={300}
            />
          </div>
          <div className={styles.header}>
            <DashboardSortTableHeader
              key={`rev-${revSortDir}`}
              title={"Reviewer"}
              value={revSortDir}
              onChange={handleRevSortDir}
              width={200}
            />
          </div>
        </div>

        <div className={styles.table_content}>
          {itemsToDisplay.length > 0 ? (
            itemsToDisplay.map((subItem, index) => {
              if (index % 2 === 0) {
                return (
                  <div className={styles.odd_row} key={index}>
                    <PassportRecordRequirementItem
                      item={subItem}
                      onClick={onItemClick}
                    />
                  </div>
                );
              } else {
                return (
                  <div className={styles.even_row} key={index}>
                    <PassportRecordRequirementItem
                      item={subItem}
                      onClick={onItemClick}
                    />
                  </div>
                );
              }
            })
          ) : (
            <div className={styles.nothing_to_display}>
              <p>No matching data.</p>
            </div>
          )}
        </div>

        {isLoading && (<div className={styles.loading}>
          <Loading />
        </div>)}
      </div>

      <div className={styles.table_pagination}>
        <DashboardTablePagination
          onPageChange={setCurrentPage}
          total={sortedItems.length}
          perPage={limit}
          currentPage={currentPage}
        />
      </div>
    </>
  );
}

PassportRecordRequirementTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      reqName: PropTypes.string.isRequired,
      src: PropTypes.string,
      freq: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      score: PropTypes.number,
      expiration: PropTypes.string.isRequired,
      reviewer: PropTypes.string.isRequired,
      createdByTypeContact: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  onItemClick: PropTypes.func,
};
