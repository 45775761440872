import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal } from "@cpnw/ui";
import { Button } from "@cpnw/ui";
import DashboardPassportFileTable from "components/DashboardPassportFileTable";
import styles from "./DashboardPassportFileModal.module.css";
import {Loading} from "@cpnw/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const DashboardPassportFileModal = ({
  isOpen,
  onClose,
  contactDataWithPassports,
  passportUpload,
  passportDelete,
  onDownloadContactPassports,
  c,
  isLoading,
  showUpload = false,
  showDownload = false,
  showDelete = false
}) => {
  const fileInputRef = useRef(null);
  const [currentView, setCurrentView] = useState("table"); // 'table' or 'upload'
  const [tableFiles, setTableFiles] = useState(
    contactDataWithPassports?.passports || []
  );
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [tempLoader, setTempLoader] = useState(isLoading);

  useEffect(() => {
    setTempLoader(isLoading)
    setTableFiles(contactDataWithPassports?.passports)

    if(contactDataWithPassports?.passports.length<1){
      setCurrentView('upload');
    } else {
      setCurrentView('table');
    }
  }, [isLoading, contactDataWithPassports])

  const handlePassportDelete = async (index) => {
    
    let passport = tableFiles[index];

    if(passport.blobName && passport.blobName.length>0){
      //requirement files
      const shouldDelete = window.confirm(`This will change the requirement status for "${passport.requirementName}" to "Not Submitted". Delete anyway?`);
      if (!shouldDelete) return;
    }

    const deleted = await passportDelete(
      contactDataWithPassports?.contactId,
      passport
    );

    if (deleted)
      setTableFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleBack = () => {
    setCurrentView("table");
  };

  const getCurrentFormattedDate = () => {
    return new Date()
      .toLocaleString("en-US", { hour12: true })
      .replace(",", "");
  };

  const handleDragOver = (event) => {
    console.log("handleDragOver")
    event.preventDefault();
  };

  const handleUploadPassport = async (files) => {
    if (!files || files.length == 0) return;

    const fileNames = await passportUpload(
      contactDataWithPassports?.contactId,
      files
    );

    if (fileNames.length == 0) return;

    // const uploadedFiles = Array.from(files)
    //   .filter((m) => fileNames.includes(m.name))
    //   .map((file) => ({
    //     name: file.name,
    //     type: file.type,
    //     size: file.size,
    //     uploadDate: getCurrentFormattedDate(),
    //   }));

    console.log("handleUploadPassport")

    setTableFiles([...tableFiles, ...fileNames]);
    setCurrentView("table");
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={styles.modalContent}
      modalClassName={styles.customModal}
    >
      {!tempLoader ? (
      <>
        <div className={styles.modalHeader}>
          <h2>Passport File Management Window</h2>
          {/* <Button className={styles.closeButton} onClick={onClose} text="Close" /> */}
          <FontAwesomeIcon icon={faXmark} style={{fontSize: "28px", color: "#dddddd", cursor: "pointer"}} onClick={onClose}/>
        </div>
        <div className={styles.lineAfter}></div>
        <div className={styles.modalBody}>
          {currentView === "table" ? (
            <>
              {showUpload && <div className={styles.fileActionsContainer}>
                <p className={styles.fileNamingGuidelines}>
                  <strong>
                    When naming files, please do not use comma (,) characters.
                    <br />
                    Recommended File-Naming:
                  </strong>{" "}
                  Location_FileName_IndividualName.pdf
                </p>
                <Button
                  className={styles.uploadButton}
                  onClick={() => setCurrentView("upload")}
                  text="UPLOAD FILES"
                />
              </div>}
              <DashboardPassportFileTable
                items={tableFiles}
                onChangeSelectedFiles={(items) => setSelectedFiles(items)}
                onDelete={(index) => handlePassportDelete(index)}
                showDelete={showDelete}
              />
              {showDownload && <div className={styles.downloadButtons}>
                <Button
                  className={styles.downloadButton}
                  onClick={() =>
                    onDownloadContactPassports(
                      contactDataWithPassports.contactId,
                      selectedFiles
                    )
                  }
                  text="DOWNLOAD SELECTED"
                  disabled={!selectedFiles || selectedFiles.length == 0}
                />
                <Button
                  className={styles.downloadButton}
                  onClick={() =>
                    onDownloadContactPassports(
                      contactDataWithPassports.contactId,
                      tableFiles
                    )
                  }
                  text="DOWNLOAD ALL"
                  disabled={!tableFiles || tableFiles.length == 0}
                />
              </div>}
            </>
          ) : <>
            {!showUpload && <div className={styles.uploadForm}>
                <h3>This student has no passports on file.</h3>
            </div>}
            {showUpload && <div className={styles.uploadForm}>
              <div className={styles.uploadInstructions}>
                <p className={styles.fileNamingGuidelines}>
                  <strong>
                    When naming files, please do not use comma (,) characters.
                    <br />
                    Recommended File-Naming:
                  </strong>{" "}
                  Location_FileName_IndividualName.pdf
                </p>
                <div
                  className={styles.uploadBox}
                  onClick={() => fileInputRef.current.click()}
                  onDrop={(e) => {
                    e.preventDefault()
                    handleUploadPassport(e.dataTransfer.files)
                  }}
                  onDragOver={handleDragOver}
                >
                  <p>Drag your files here or click in this area</p>
                  <input
                    type="file"
                    multiple
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={(e) => handleUploadPassport(e.target.files)}
                  />
                </div>
                {/* This student has no passports on file. */}
              </div>
              {tableFiles.length>0 && (
                <Button
                  className={styles.backButton}
                  onClick={handleBack}
                  text="Back"
                />
              )}
            </div>
            }
          </>}
        </div>
      </>
      ) : (
        <>
          <div className={styles.modalHeader}>
            <h2>Passport File Management Window</h2>
            {/* <Button className={styles.closeButton} onClick={onClose} text="Close" /> */}
            <FontAwesomeIcon icon={faXmark} style={{fontSize: "28px", color: "#dddddd", cursor: "pointer"}} onClick={onClose}/>
          </div>
          <div className={styles.pass_loader}>
            <Loading />
          </div>
        </>
      )}
    </Modal>
  );
};

DashboardPassportFileModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  contactDataWithPassports: PropTypes.objectOf({
    contactId: PropTypes.any,
    passports: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        uploadDate: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  onDownloadContactPassports: PropTypes.func.isRequired,
};

export default DashboardPassportFileModal;
