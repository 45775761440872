import React, { useState } from "react";
import PropTypes from "prop-types";

import styles from "./PassportRecordRequirements.module.css";
import PassportRecordRequirementTable from "components/PassportRecordRequirementTable";
import { HeaderSection } from "@cpnw/ui";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faBan,
  faStarOfLife,
  faHourglassStart,
  faHourglassHalf,
  faExclamation
} from "@fortawesome/free-solid-svg-icons";

export default function PassportRecordRequirements({ tableData, onItemView }) {
  
  return (
    <>
      <div className={styles.record_requirements_container}>
        <div className={styles.upper_info}>
          <HeaderSection header={"Requirements"} />
          <p>
            To submit a requirement click on the name in the table below. Upon
            clicking the name, you will be taken to a page where you can
            complete, submit, or view the requirement.
          </p>

          {/* Table Key */}
          <div className={styles.key}>
            <div className={styles.key_item}>
              <p className={styles.bold}>Key:</p>
            </div>
            <div className={styles.key_item}>
              <FontAwesomeIcon
                icon={faCheck}
                style={{
                  color: "#007e8d",
                  fontSize: "20px",
                  marginTop: "3px",
                  marginRight: "8px",
                }}
              />
              <p>Approved</p>
            </div>
            <div className={styles.key_item}>
              <FontAwesomeIcon
                icon={faBan}
                style={{
                  color: "red",
                  fontSize: "16px",
                  rotate: "90deg",
                  marginTop: "3px",
                  marginRight: "8px",
                }}
              />
              <p>Rejected</p>
            </div>
            <div className={styles.key_item}>
              <FontAwesomeIcon
                icon={faStarOfLife}
                style={{
                  color: "#aa9b73",
                  fontSize: "16px",
                  marginTop: "3px",
                  marginRight: "8px",
                }}
              />
              <p>Waiver</p>
            </div>
            <div className={styles.key_item}>
              <FontAwesomeIcon
                icon={faHourglassStart}
                style={{
                  color: "red",
                  fontSize: "16px",
                  rotate: "180deg",
                  marginTop: "3px",
                  marginRight: "8px",
                }}
              />
              <p>Expired</p>
            </div>
            <div className={styles.key_item}>
              <FontAwesomeIcon
                icon={faExclamation}
                style={{
                  color: "#000000",
                  fontSize: "16px",
                  marginTop: "3px",
                  marginRight: "8px",
                }}
              />
              <p>Expiring</p>
            </div>
          </div>
        </div>

        <div className={styles.table}>
          <PassportRecordRequirementTable
            items={tableData}
            onItemClick={onItemView}
          />
        </div>
      </div>
    </>
  );
}

PassportRecordRequirements.propTypes = {};
