import React from 'react';
import classNames from 'classnames';
import PropTypes from "prop-types";
import styles from './PassportDownloadItem.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const PassportDownloadItem = ({ file }) => {
  return (
    <a className={styles.passportDownloadItem} href={(file && file.url) ? file.url : "#"} target="_blank" download={(file && file.name) ? file.name : undefined}>
      <FontAwesomeIcon icon={faDownload} size="lg" className={classNames(styles.downloadIcon)} />
      <span>{(file && file.name) ? file.name : ""}</span>
    </a>
  );
};

PassportDownloadItem.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default PassportDownloadItem;
