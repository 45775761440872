import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './PassportStudentInfoReviewer.module.css';

export default function PassportStudentInfoReviewer({ student }) {
    //tooltip hover state
    const [tooltip, setTooltip] = useState(false)
    //trunication state
    const [isTextTruncated, setIsTextTruncated] = useState(false);

    const [isPreviousNameModalOpen, setIsPreviousNameModalOpen] = useState(false);

    //ref for email (only value that gets trunicated)
    const emailRef = useRef(null);

    useEffect(() => {
        //on mount call function to check if trunication is present
        checkTextTruncation();
    }, []);

    const checkTextTruncation = () => {
        if (emailRef.current) {
            setIsTextTruncated(emailRef.current.scrollWidth > emailRef.current.clientWidth);
        }
    };

    return (
        <>
            <div className={styles.student_info}>
                {/* <div className={classNames(styles.header, styles.stickyHeader)}>
                <h3>{student.lastName}, {student.firstName}</h3>
            </div> */}

                <div className={styles.info_row}>
                    <div className={styles.info_item}>
                        <p>School/Campus:</p>
                        <strong className={styles.info_data}>{student.school}</strong>
                    </div>

                    <div className={styles.info_item}>
                        <p>Program:</p>
                        <strong className={styles.info_data}>{student.program}</strong>
                    </div>

                    <div className={styles.info_item}>
                        <p>DOB:</p>
                        <strong className={styles.info_data}>
                            {new Date(student.dob).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                            })}
                        </strong>
                    </div>

                    <div className={classNames(styles.info_item, styles.info_email, styles.tooltip_container)} onMouseEnter={() => isTextTruncated && setTooltip(true)} onMouseLeave={() => setTooltip(false)}>
                        <p>Email:</p>
                        <strong className={styles.elip_data} ref={emailRef}>{student.email}</strong>

                        <div className={classNames(tooltip ? styles.active : styles.hidden)}>
                            {student.email}
                        </div>
                    </div>

                    <div className={styles.info_item}>
                        <p>Contact Phone:</p>
                        <strong className={styles.info_data}>{student.phone}</strong>
                    </div>

                    <div className={styles.info_item}>
                        <p>Previous name:</p>
                        {student.previousNames.length === 0 ? (
                            <strong className={styles.info_data}>N/A</strong>
                        ) : student.previousNames.length === 1 ? (
                            <strong className={styles.info_data}>
                                {student.previousNames}
                            </strong>
                        ) : (
                            <>
                                <strong className={styles.info_data}>
                                    {student.previousNames[0]}
                                </strong>

                                <button
                                    style={{
                                        all: "unset",
                                        fontWeight: "bold",
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => setIsPreviousNameModalOpen(true)}
                                >
                                    More...
                                </button>

                                {isPreviousNameModalOpen && (
                                    <div className={styles.modal}>
                                        <div className={styles.modal_content}>
                                            <h3>Previous Names</h3>
                                            <div className={styles.modal_scrollable}>
                                                <ul>
                                                    {student.previousNames.map((name, index) => (
                                                        <li key={index}>
                                                            {name}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <button
                                                className={styles.close_button}
                                                onClick={() => setIsPreviousNameModalOpen(false)}
                                            >
                                                Close
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

PassportStudentInfoReviewer.propTypes = {
    student: PropTypes.shape({
        lastName: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        previousNames: PropTypes.arrayOf(
            PropTypes.shape({
                NameFirst: PropTypes.string.isRequired,
                NameLast: PropTypes.string.isRequired,
            })
        ).isRequired,
        school: PropTypes.string.isRequired,
        program: PropTypes.string.isRequired,
        sidEid: PropTypes.string.isRequired,
        dob: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        phone: PropTypes.string,
        eContact: PropTypes.string.isRequired,
        eContactPhone: PropTypes.string.isRequired
    })
}