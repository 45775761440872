export const StaticRoutes = {
    Root: "/",

    GetStarted: "/GetStarted",
    GetStartedSchools: "/GetStarted/Schools",
    GetStartedStudents: "/GetStarted/Students",
    GetStartedHealthcare: "/GetStarted/Healthcare",

    AboutUs: "/AboutUs",
    AboutUsHealthcarePartners: "/AboutUs/HealthcarePartners",
    AboutUsParticipatingPrograms: "/AboutUs/ParticipatingPrograms",
    AboutUsNursingPlacement: "/AboutUs/NursingPlacement",


    AccountRegister: "/Account/Register",

    MyDashboard: "/",

    OurServices: "/OurServices",

    FAQ: "/FAQ",

    //Clinical Prerequisites
    Passport: "/Passport",
    SiteRequirements: "/SiteRequirements",
    eLearning: "/eLearning",

    //My Records
    eLearningResults: "/eLearningResults",
    OIGSAM: "/OIGSAM",
    MyRecordsRequirements: "/MyRequirements",

    //My Account
    MyAccount: "/MyAccount",
    MyProfile: "/MyProfile",
    MySecuritySettings: "/MySecuritySettings",
    MyDemographics: "/MyDemographics",
    AccountConsent: "/Consent",
    AccountSelfPay: "/Account/SelfPay",

    //Admin
    AdminReports: "/Admin/Reports",
    AdminAssignments: "/Admin/Assignments",
    AdminUsers: "/Admin/Users",
    AdminReview: "/Admin/Review",
    AdminHealthcareReview: "/Admin/HealthcareReview",
    AdminReviewer: "/Admin/Reviewer",
    AdminBackgroundWatch: "/Admin/BackgroundWatch",

    //Impersonate
    ImpersonateUser: "/Admin/impersonate",

    //Documents
    AnnualMeeting: "/Documents/AnnualMeeting",
    Board: "/Documents/Board",
    Corporate: "/Documents/Corporate",
    Financial: "/Documents/Financial",
    East: "/Documents/East",
    North: "/Documents/North",
    South: "/Documents/South",

    //Requirements
    Requirements: "/Requirements",
    RequirementForm: "/Requirement/Form",

    //AccountRegistrationProfile
    AccountRegistrationProfile: "/Account/RegistrationProfile",


}