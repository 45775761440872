import { Auth } from "auth";
import environment from "environment";
import { createContainer } from "unstated-next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  makeAuthedMultipartRequest,
  makeAuthedRequest,
} from "utils/makeAuthedRequest";
import { useState } from "react";

export function useRequirement() {
  const [requirement, setRequirement] = useState(null);

  const queryClient = useQueryClient();

  const { data: adminRequirements, refetch: refetchAdminRequirements } =
    useQuery(
      ["AdminRequirementsGet"],
      async () => {
        const res = await makeAuthedRequest(`/cpnw/requirements/admin`, {
          method: "GET",
        });

        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(JSON.stringify(body));
        }
      },
      {
        enabled: false,
      }
    );

  const {
    data: requirementCategoriesData,
    refetch: refetchRequirementCategories,
  } = useQuery(
    ["CategoriesGet"],
    async () =>
      await makeAuthedRequest(`/cpnw/categories`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(JSON.stringify(body));
        }
      }),
    {
      enabled: false, // Prevent automatic fetching
    }
  );

  const {
    data: requirementDisciplinesData,
    refetch: refetchRequirementDisciplines,
  } = useQuery(
    ["RequirementDisciplinesGet"],
    async () =>
      await makeAuthedRequest(`/cpnw/requirement/disciplines`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(JSON.stringify(body));
        }
      }),
    {
      enabled: false, // Prevent automatic fetching
    }
  );

  const {
    data: requirementFacilitiesData,
    refetch: refetchRequirementFacilities,
  } = useQuery(
    ["RequirementFacilitiesGet"],
    async () =>
      await makeAuthedRequest(`/cpnw/requirement/facilities`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(JSON.stringify(body));
        }
      }),
    {
      enabled: false, // Prevent automatic fetching
    }
  );

  const { data: requirementStatusesData, refetch: refetchRequirementStatuses } =
    useQuery(
      ["RequirementStatusesGet"],
      async () =>
        await makeAuthedRequest(`/cpnw/RequirementStatuses`, {
          method: "GET",
        }).then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const body = await res.json();
            throw new Error(JSON.stringify(body));
          }
        }),
      {
        enabled: false, // Prevent automatic fetching
      }
    );

  const {
    data: contactRequirementStatusesData,
    refetch: refetchContactRequirementStatuses,
  } = useQuery(
    ["ContactRequirementStatusesGet"],
    async () =>
      await makeAuthedRequest(`/cpnw/ContactRequirementStatuses`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(JSON.stringify(body));
        }
      }),
    {
      enabled: false, // Prevent automatic fetching
    }
  );

  const { data: frequenciesData, refetch: refetchFrequencies } = useQuery(
    ["FrequenciesGet"],
    async () =>
      await makeAuthedRequest(`/cpnw/Frequencies`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(JSON.stringify(body));
        }
      }),
    {
      enabled: false, // Prevent automatic fetching
    }
  );

  const { data: requirementSelectboxValuesData, refetch: refetchRequirementSelectboxValues } = useQuery(
    ["RequirementSelectboxValues"],
    async () =>
      await makeAuthedRequest(`/cpnw/RequirementSelectboxValues`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(JSON.stringify(body));
        }
      }),
    {
      enabled: false, // Prevent automatic fetching
    }
  );

  const { data: abbreviationsData, refetch: refetchAbbreviations } = useQuery(
    ["abbreviationsData"],
    async () =>
      await makeAuthedRequest(`/cpnw/Requirements/UsedAbbreviations`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(JSON.stringify(body));
        }
      }),
    {
      enabled: false, // Prevent automatic fetching
    }
  );

  const createRequirement = async (requirement, files, learningModule) => {
    var form = new FormData();
    form.append("payload", JSON.stringify(requirement));
    if (learningModule) {
      form.append("learningModule", learningModule);
    }
    for (const file of files) form.append("files", file);

    const res = await makeAuthedMultipartRequest(
      `/cpnw/requirement/admin`,
      form,
      {
        method: "Post",
      }
    );

    if (res.ok) return res.json();

    const body = await res.json();
    throw new Error(JSON.stringify(body));
  };

  const updateRequirement = async (requirement, files, learningModule) => {
    var form = new FormData();
    form.append("payload", JSON.stringify(requirement));
    if (learningModule) {
      form.append("learningModule", learningModule);
    }
    for (const file of files) form.append("files", file);

    const res = await makeAuthedMultipartRequest(
      `/cpnw/requirement/admin`,
      form,
      {
        method: "Put",
      }
    );

    if (res.ok) return res.json();

    const body = await res.json();
    throw new Error(JSON.stringify(body));
  };

  const deleteRequirement = async (id) => {
    const res = await makeAuthedRequest(
      `/cpnw/requirement/${id}/admin`,
      {
        method: "Delete",
      }
    );

    if (res.ok) return res.json();

    const body = await res.json();
    throw new Error(JSON.stringify(body));
  };

  const { data: contactsToReviewData, refetch: refetchContactsToReviewData } =
    useQuery(
      ["ReviewerRequirementsGet"],
      async () => {
        const res = await makeAuthedRequest(`/cpnw/requirements/reviewer`, {
          method: "GET",
        });

        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(JSON.stringify(body));
        }
      },
      {
        enabled: false,
      }
    );

  const { data: myRequirementsData, refetch: refetchMyRequirements } = useQuery(
    ["MyRequirementsGet"],
    async () =>
      await makeAuthedRequest(`/cpnw/requirements/user`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(JSON.stringify(body));
        }
      }),
    {
      enabled: false, // Prevent automatic fetching
    }
  );

  const { data: myAssignmentsData, refetch: refetchMyAssignments } = useQuery(
    ["MyAssignmentsGet"],
    async () =>
      await makeAuthedRequest(`/cpnw/assignments/user`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(JSON.stringify(body));
        }
      }),
    {
      enabled: false, // Prevent automatic fetching
    }
  );

  const { data: contactNoticesData, refetch: refetchContactNotices } = useQuery(
    ["contactNoticesData"],
    async () =>
      await makeAuthedRequest(`/cpnw/Contact/Notices`, {
        method: "GET",
      }).then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const body = await res.json();
          throw new Error(JSON.stringify(body));
        }
      }),
    {
      enabled: false, // Prevent automatic fetching
    }
  );

  const useRequirementMessages = (contactRequirementId) => {
    return useQuery(
      ["RequirementMessages", { contactRequirementId }],
      async () =>
        await makeAuthedRequest(
          `/cpnw/requirement/${contactRequirementId}/messages`,
          {
            method: "GET",
          }
        ).then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const body = await res.json();
            throw new Error(JSON.stringify(body));
          }
        }),
      {
        enabled: !!contactRequirementId,
      }
    );
  };

  const useStudentRequirements = (contactId) => {
    return useQuery(
      ["useStudentRequirements", { contactId }],
      async () =>
        await makeAuthedRequest(
          `/cpnw/requirements/reviewer/contact/${contactId}`,
          {
            method: "GET",
          }
        ).then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const body = await res.json();
            throw new Error(JSON.stringify(body));
          }
        }),
      {
        enabled: !!contactId,
      }
    );
  };

  const postRequirementMessage = async ({ contactRequirementId, message }) => {
    const res = await makeAuthedRequest(`/cpnw/requirement/messages`, {
      method: "POST",
      body: JSON.stringify({ contactRequirementId, messageBody: message }),
    });
    if (res.ok) {
      const result = await res.json();
      return result;
    } else {
      const body = await res.json();
      throw new Error(JSON.stringify(body));
    }
  };

  const submitRequirement = async (requirement, files) => {
    var form = new FormData();
    form.append("payload", JSON.stringify(requirement));

    for (const file of files) form.append("files", file);

    const res = await makeAuthedMultipartRequest(
      `/cpnw/requirement/user`,
      form,
      {
        method: "PUT",
      }
    );

    if (res.ok) return res.json();

    const body = await res.json();
    throw new Error(JSON.stringify(body));
  };

  const postApproval = async (dto, files) => {
    var form = new FormData();
    form.append("payload", JSON.stringify(dto));

    for (const file of files) form.append("files", file);

    const res = await makeAuthedMultipartRequest(
      `/cpnw/requirement/review`,
      form,
      {
        method: "POST",
      }
    );

    if (res.ok) return res.json();

    const body = await res.json();
    throw new Error(JSON.stringify(body));
  };

  const postVerificationComplete = async (contactId) => {
    const res = await makeAuthedRequest(`/cpnw/requirement/reviewer/contact/${contactId}/VerificationComplete`, {
      method: "POST"
    });

    if (res.ok) return res.json();

    const body = await res.json();
    throw new Error(JSON.stringify(body));
  };

  return {
    adminRequirements,
    refetchAdminRequirements,

    requirementCategoriesData,
    refetchRequirementCategories,
    requirementDisciplinesData,
    refetchRequirementDisciplines,
    requirementFacilitiesData,
    refetchRequirementFacilities,
    requirementStatusesData,
    refetchRequirementStatuses,
    contactRequirementStatusesData,
    refetchContactRequirementStatuses,
    frequenciesData,
    refetchFrequencies,
    abbreviationsData,
    refetchAbbreviations,
    requirementSelectboxValuesData,
    refetchRequirementSelectboxValues,

    requirement,
    setRequirement,

    createRequirement,
    updateRequirement,
    deleteRequirement,

    contactsToReviewData,
    refetchContactsToReviewData,

    myRequirementsData,
    refetchMyRequirements,

    useRequirementMessages,
    postRequirementMessage,

    submitRequirement,

    useStudentRequirements,

    postApproval,
    postVerificationComplete,

    contactNoticesData,
    refetchContactNotices,

    myAssignmentsData,
    refetchMyAssignments

  };
}
export const RequirementContainer = createContainer(useRequirement);
