import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import styles from "./PassportCreateRequirements.module.css";

import DashboardSelect from "../DashboardSelect";
import DashboardPassportFileEditorItem from "components/DashboardPassportFileEditorItem/DashboardPassportFileEditorItem";
import PassportDescriptionList from "components/PassportDescriptionList";
import CheckboxSelect from "components/CheckboxSelect";
import PassportPreviewButton from "components/PassportPreviewButton";
import { HeaderSection } from "@cpnw/ui";
import { InputBox } from "@cpnw/ui";
import { AddButton } from "@cpnw/ui";
import { Button } from "@cpnw/ui";
import { CheckboxItem, Modal } from "@cpnw/ui";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import classNames from "classnames";
import CustomReactQuill from "components/_Helpers/CustomReactQuill";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import RequiredByDropdownED from "components/RequiredByDropdownED";

export default function PassportCreateRequirements({
  selectFields,
  textValues,
  selectedValues = {},
  isNew = true,
  onSave,
  showWaiver = true,
  showWeeksBeforePlacement = true,
  showBusinessUnitsInsteadOfFacilities = true
}) {

  const [error, setError] = useState("");

  const [title, setTitle] = useState(selectedValues.title || "");
  const [instructions, setInstructions] = useState(
    selectedValues.instructions || ""
  );
  const [abbr, setAbbr] = useState(selectedValues.abbr || "");
  const [categoryState, setCategoryState] = useState(
    selectedValues.category || null
  );
  const [subCategoryState, setSubCategoryState] = useState(
    selectedValues.subCategory || null
  );
  const [subCategories, setSubCategories] = useState([]);
  const [frequencyState, setFrequencyState] = useState(
    selectedValues.frequency || null
  );
  const [statusState, setStatusState] = useState(selectedValues.status || null);
  const [requiredByState, setRequiredByState] = useState(
    selectedValues.requiredBy || []
  );
  const [requiredByBusinessUnits, setRequiredByBusinessUnits] = useState(
    selectedValues.requiredByBusinessUnits || []
  );
  const [appliesToState, setAppliesToState] = useState(
    selectedValues.appliesTo || []
  );
  const [userTypes, setUserTypes] = useState(
    (selectedValues.appliesToUserTypes || "").split(",").filter(a=>a != "")
  );
  const [uploadedFiles, setUploadedFiles] = useState(
    selectedValues.files || []
  );
  const [filesToUpload, setFilesToUpload] = useState([]);

  const [allowWaiver, setAllowWaiver] = useState(
    selectedValues.allowWaiver || false
  );
  const [weeksBeforePlacement, setWeeksBeforePlacement] = useState(
    selectedValues.weeksBeforePlacement || null
  );

  const [uploadedModule, setUploadedModule] = useState(selectedValues.module);
  const [moduleToUpload, setModuleToUpload] = useState(null);

  const [isForceRetakeModalOpen, setIsForceRetakeModalOpen] = useState(false);

  useEffect(() => {
    const cat = selectFields.categories.find((a) => a.Name == categoryState);
    if (cat) {
      const subs = selectFields.subCategories.filter(
        (a) => a.CategoryId == cat.Id
      );
      setSubCategories(subs.map((a) => a.Name));
    }
  }, [categoryState, selectFields.subCategories, selectFields.categories]);

  const renderListOfFiles = (attachments) => {
    return attachments.map((file) => {
      return (
        <DashboardPassportFileEditorItem
          name={file.name}
          onView={() => {
            window.open(file.url, "_blank");
          }}
          onDelete={() => {
            if (file.url.startsWith("blob")) {
              //local files
              setFilesToUpload((files) => files.filter((f) => file.id != f.id));
            } else {
              //remote files
              setUploadedFiles((files) => files.filter((f) => file.id != f.Id));
            }
          }}
        />
      );
    });
  };

  const validFields = (dto) => {
    setError("");

    //Don't enforce unique ABBR anymore
    // if (
    //   selectFields.abbreviations.includes(dto.abbr) &&
    //   selectedValues.abbr != dto.abbr
    // ) {
    //   setError("Abbreviation is already in used!");
    //   return false;
    // }

    //other validations

    return true;
  };

  const onSaveForceRetake = (ev) => {
    const dto = {
      title,
      instructions,
      abbr,
      category: categoryState,
      subCategory: subCategoryState,
      requiredBy: requiredByState,
      requiredByBusinessUnits: requiredByBusinessUnits,
      appliesTo: appliesToState,
      frequency: frequencyState,
      status: statusState,
      files: uploadedFiles,
      newFiles: filesToUpload,
      module: uploadedModule,
      newModule: moduleToUpload,
      forceRetake: true,
      allowWaiver: allowWaiver,
      weeksBeforePlacement: weeksBeforePlacement,
      appliesToUserTypes: userTypes,
    };
    console.log("onSaveForceRetake", dto);

    if (validFields(dto)) {
      onSave(dto);
    }
  };

  const onSaveClick = (ev) => {
    const dto = {
      title,
      instructions,
      abbr,
      category: categoryState,
      subCategory: subCategoryState,
      requiredBy: requiredByState,
      requiredByBusinessUnits: requiredByBusinessUnits,
      appliesTo: appliesToState,
      frequency: frequencyState,
      status: statusState,
      files: uploadedFiles,
      newFiles: filesToUpload,
      module: uploadedModule,
      newModule: moduleToUpload,
      allowWaiver: allowWaiver,
      weeksBeforePlacement: weeksBeforePlacement,
      appliesToUserTypes: userTypes,
    };

    console.log("onSaveClick dto", dto);

    if (validFields(dto)) {
      onSave(dto);
    }
  };

  const onFileChange = useCallback(
    (e) => {
      const files = e.target.files;
      for (const f of files) {
        f.id = Math.random();
      }
      console.log("onFileChange", files);
      setFilesToUpload([...filesToUpload, ...files]);
    },
    [filesToUpload]
  );

  const onModuleFileChange = useCallback(
    (e) => {
      const file = e.target.files[0];
      console.log("onModuleFileChange", file);
      setModuleToUpload(file);
    },
    [moduleToUpload]
  );

  const onAddFileClick = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    fileInputRef.current.click();
  };
  const onUploadModuleButton = (ev) => {
    moduleFileInputRef.current.click();
  };

  const fileInputRef = useRef(null);
  const moduleFileInputRef = useRef(null);

  const filesToRender = useMemo(() => {
    var files = uploadedFiles.map((a) => ({
      id: a.Id,
      name: a.Name,
      url: a.Url,
    }));

    for (const f of filesToUpload) {
      files.push({
        id: f.id,
        name: f.name,
        url: URL.createObjectURL(f),
      });
    }
    return files;
  }, [uploadedFiles, filesToUpload]);

  return (
    <>
      <HeaderSection
        header={isNew ? "Create Requirement" : "Edit Requirement"}
      />
      {/* <div className={styles.descriptionList}>
        <PassportDescriptionList
          header="Create Requirement Basic Template"
          items={[
            'Default beginning state would be inactive. This would allowcreation/editing before putting into service by saving in "Active" state.',
            "Statewide requirements are updated annually based on fiscal year of July 1 to June 30.",
            "Site specific requirements can be added at any time.",
          ]}
        />
      </div> */}
      <Modal
        isOpen={isForceRetakeModalOpen}
        onRequestClose={() => {
          setIsForceRetakeModalOpen(false);
        }}
        className={classNames([])}
      >
        <div className={styles.warningTitle}>
          <FontAwesomeIcon icon={faWarning}></FontAwesomeIcon> This action
          cannot be reverted!
        </div>
        <ul className={styles.warningList}>
          <li>
            This action will make the requirement ACTIVE and publish to all
            students for the selected Required By and Applies To.
          </li>
          <li>
            The associated eLearning module will be marked
            Expired to require all students to retake the module.
          </li>
        </ul>
        <div>Do you want to continue?</div>
        <div className={styles.warningButtons}>
          <Button
            text={"YES"}
            type={"submit"}
            className={styles.customButton}
            onClick={(ev) => {
              onSaveForceRetake(ev);
            }}
          />
          <Button
            text={"NO"}
            type={"cancel"}
            className={styles.customButton}
            onClick={() => {
              setIsForceRetakeModalOpen(false);
            }}
          />
        </div>
      </Modal>
      <div className={styles.container}>
        {/* Column 1 */}
        <div className={styles.column}>
          <fieldset>
            {/* Row 1 */}
            <div className={styles.row}>
              <div className={styles.column}>
                <InputBox
                  placeholder="Name"
                  label="Name"
                  value={title}
                  setValue={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </div>
              <div className={styles.column}>
                <label>Required By</label>
                {!showBusinessUnitsInsteadOfFacilities && <CheckboxSelect
                  placeholder="Select"
                  options={selectFields.requiredBy}
                  tip="Select Facilities"
                  onSelect={setRequiredByState}
                  selected={requiredByState}
                />}
                {/* {showBusinessUnitsInsteadOfFacilities && <CheckboxSelect
                  placeholder="Select"
                  options={selectFields.requiredByBusinessUnits}
                  tip="Select Programs"
                  onSelect={setRequiredByBusinessUnits}
                  selected={requiredByBusinessUnits}
                />} */}
                {showBusinessUnitsInsteadOfFacilities && <RequiredByDropdownED
                  placeholder="Select"
                  options={selectFields.requiredByBusinessUnits}
                  tip="Select Programs"
                  onSelect={setRequiredByBusinessUnits}
                  selected={requiredByBusinessUnits}
                />}
              </div>
              <div className={styles.column}>
                <label>Category</label>
                <DashboardSelect
                  placeholder="Select"
                  options={selectFields.category}
                  tip="Select Category"
                  selected={categoryState}
                  onSelect={setCategoryState}
                />
              </div>
              {subCategories && subCategories.length > 0 && (
                <div className={styles.column}>
                  <label>Sub-Category</label>
                  <DashboardSelect
                    placeholder="Select"
                    options={subCategories}
                    tip="Select Sub-Category"
                    selected={subCategoryState}
                    onSelect={setSubCategoryState}
                  />
                </div>
              )}
            </div>
            {/* Row 2 */}
            <div className={styles.row}>
              <div className={styles.column}>
                <label>Frequency</label>
                <DashboardSelect
                  placeholder="Select"
                  options={selectFields.frequency}
                  tip="Select Frequency"
                  onSelect={setFrequencyState}
                  selected={frequencyState}
                />
              </div>
              {!showBusinessUnitsInsteadOfFacilities && <div className={styles.column}>
                <label>Applies To</label>
                <CheckboxSelect
                  placeholder="Select"
                  options={selectFields.appliesTo}
                  tip="Select Application"
                  onSelect={setAppliesToState}
                  selected={appliesToState}
                />
              </div>}
              <div className={styles.column}>
                <label>User Type</label>
                <CheckboxSelect
                  placeholder="Select"
                  options={selectFields.appliesToUserTypes}
                  tip="Select User Types"
                  onSelect={setUserTypes}
                  selected={userTypes}
                />
              </div>
            </div>
            {/* Row 3 */}
            <div className={styles.row}>
              <div className={styles.abbreviation}>
                <InputBox
                  placeholder=""
                  label="Abbr."
                  value={abbr}
                  setValue={(e) => {
                    const value = e.target.value.toUpperCase();

                    setAbbr(value);
                  }}
                />
              </div>
              <div className={styles.column}>
                <label>Status</label>
                <DashboardSelect
                  placeholder="Select"
                  options={selectFields.status}
                  tip="Select Status"
                  onSelect={setStatusState}
                  selected={statusState}
                />
              </div>
              {showWeeksBeforePlacement && (
                <div className={styles.column}>
                  <label>Due date before placement</label>
                  <DashboardSelect
                    placeholder="Select"
                    options={[2, 4, 6, 8, 10]}
                    tip="Select due date in weeks before placement"
                    onSelect={setWeeksBeforePlacement}
                    selected={weeksBeforePlacement}
                  />
                </div>
              )}
              {showWaiver && (
                <div className={styles.column}>
                  <CheckboxItem
                    className={styles.checkbox}
                    onClick={(isChecked) => setAllowWaiver(!!isChecked)}
                    description={"Allow Waiver"}
                    isChecked={allowWaiver}
                  />
                </div>
              )}
            </div>
            {/* Row 4 */}
            <div className={styles.row}>
              {/* If eLearning is selected else render the normal block*/}
              {categoryState === "eLearning" ? (
                <div className={styles.column}>
                  <hr></hr>
                  <div className={styles.row}>
                    {moduleToUpload ? (
                      <div className={styles.eLearning}>
                        <DashboardPassportFileEditorItem
                          key={"module"}
                          name={moduleToUpload.name}
                          canView={false}
                          onDelete={() => {
                            setModuleToUpload(null);
                          }}
                        />
                      </div>
                    ) : uploadedModule ? (
                      <div className={styles.eLearning}>
                        <DashboardPassportFileEditorItem
                          key={"module"}
                          name={uploadedModule.name}
                          canView={true}
                          onDelete={() => {
                            setUploadedModule(null);
                          }}
                          onView={() => {
                            console.log("Viewing module ", uploadedModule);
                            const url = uploadedModule.pathname + "/index.html";
                            window.open(url, "_blank");
                          }}
                        />
                      </div>
                    ) : (
                      <div className={styles.eLearning}>
                        <AddButton
                          onClick={onUploadModuleButton}
                          label={"Upload Module"}
                        />
                        <input
                          type="file"
                          onChange={onModuleFileChange}
                          ref={moduleFileInputRef}
                          style={{ display: "none" }}
                        />
                      </div>
                    )}
                  </div>
                  <hr></hr>
                </div>
              ) : (
                <div className={styles.column}>
                  <label>Requirement Details</label>
                  <div className={styles.quillContainer}>
                    <CustomReactQuill
                      theme="snow"
                      value={instructions}
                      onChange={setInstructions}
                      placeholder={"Instructions for requirement"}
                      bounds="#parent"
                    />
                  </div>
                  <hr></hr>
                </div>
              )}
            </div>
          </fieldset>
          {/* Row 4 - Submission */}
          <div className={styles.error}>{error}</div>
          <div className={styles.submission}>
            <Button
              text={"SAVE"}
              type={"submit"}
              className={styles.customButton}
              onClick={onSaveClick}
            />
            {categoryState == "eLearning" &&
              (uploadedModule || moduleToUpload) && (
                <Button
                  text={"Save & Force Retake"}
                  type={"submit"}
                  className={styles.customButton}
                  onClick={() => {
                    setIsForceRetakeModalOpen(true);
                  }}
                />
              )}
          </div>
        </div>
        {/* Veritcal rule */}
        <div className={styles.verticalRule}></div>
        {/* Column 2 */}
        <div className={classNames(styles.column, styles.column2)}>
          <div>
            <div className={styles.instructions}>
              <h4>Instructions</h4>
              <p>{textValues.instructions}</p>
            </div>
            <div className={styles.linkedFiles}>
              <h4>Linked Files</h4>
              <AddButton onClick={onAddFileClick} label="Add File" />
              <input
                type="file"
                multiple="true"
                onChange={onFileChange}
                ref={fileInputRef}
                style={{ display: "none" }}
              />
              <ul>{renderListOfFiles(filesToRender)}</ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

PassportCreateRequirements.propTypes = PropTypes.shape({
  selectFields: PropTypes.shape({
    requiredBy: PropTypes.array,
    category: PropTypes.array,
    frequency: PropTypes.array,
    appliesTo: PropTypes.array,
    status: PropTypes.array,
  }),
  selectedValues: PropTypes.shape({
    requiredBy: PropTypes.array,
    category: PropTypes.string,
    frequency: PropTypes.string,
    appliesTo: PropTypes.array,
    status: PropTypes.string,
    title: PropTypes.string,
    instructions: PropTypes.string,
    abbr: PropTypes.string,
  }),
  textValues: PropTypes.shape({
    instructions: PropTypes.string,
  }),
  onSave: PropTypes.func,
});
