import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "./PassportRequirementReviewerView.module.css";

import { AddButton, CheckboxItem } from "@cpnw/ui";
import FormDatePicker from "../FormDatePicker";
import PassportDownloadItem from "../PassportDownloadItem";
import DashboardPassportFileEditorItem from "../DashboardPassportFileEditorItem";
import DashboardRadio from "../DashboardRadio";
import PassportMessageForm from "../PassportMessageForm";
import PassportReviewItem from "../PassportReviewItem";

import { Button, InputBox, Loading, SelectBox } from "@cpnw/ui";
import { RequirementContainer } from "hooks/useRequirement";
import { format } from "date-fns";
import QuestionToggle from "components/QuestionToggle";
import classNames from "classnames";
import { formatUTCMidnightToSameDay } from "utils/helpers";
import { DeclinationEnum } from "components/PassportUserInputView/PassportUserInputView";
import InsuranceView from "components/RequirementViews/InsuranceView";
import ScreenRespiratorView from "components/RequirementViews/ScreenRespiratorView";
import ImmunizationCovidView from "components/RequirementViews/ImmunizationCovidView";
import ImmunizationTdapView from "components/RequirementViews/ImmunizationTdapView";
import ImmunizationVaricellaView from "components/RequirementViews/ImmunizationVaricellaView";
import ImmunizationMMRView from "components/RequirementViews/ImmunizationMMRView";
import ImmunizationHepBView from "components/RequirementViews/ImmunizationHepBView";
import ImmunizationTBView from "components/RequirementViews/ImmunizationTBView";
import { Categories, ContactTypes } from "pages/enums";
import { SubmissionTypes } from "components/RequirementViews/InsuranceView/InsuranceView";
import { AccountContainer } from "hooks/useAccount";

const states = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export default function PassportRequirementReviewerView({
  requirement,
  studentContactID,
  onSaveComplete,
  showRequirementInstructions = true,
  studentInfo,
}) {
  const {
    useRequirementMessages,
    postRequirementMessage,
    postApproval,
    submitRequirement,
  } = RequirementContainer.useContainer();

  const { data: messagesData, refetch: refetchMessages } =
    useRequirementMessages(requirement.contactRequirementId);
  const messages = (
    (messagesData?.length > requirement.messages?.length
      ? messagesData
      : requirement.messages) || []
  ).map((a) => {
    return {
      name: a.FromContact,
      date: format(a.RepliedOn, "Pp"),
      message: a.Message,
    };
  });

  const canReview = requirement.canReview;
  const canView = requirement.canView;
  const canEdit = requirement.canEdit;
  const expirationDateRequired = requirement.frequency != "Once";

  const { userRoles } = AccountContainer.useContainer();
  const roles = userRoles.map((a) => a.toUpperCase());
  const canDeleteFile = () => {
    if (
      roles.includes("CPNW-REVIEWER") ||
      roles.includes("EDUCATION") ||
      roles.includes("HEALTHCARE")
    ) {
      return true;
    }
    return false;
  };

  const [errors, setErrors] = useState([]);

  const handleDeclinationChange = (value) => {
    setDeclination(value);
  };

  const handleOnSend = useCallback(
    (value) => {
      console.log("requirement", requirement);
      postRequirementMessage({
        contactRequirementId: requirement.contactRequirementId,
        message: value,
      }).then(() => {
        refetchMessages();
      });
    },
    [requirement]
  );

  const [declination, setDeclination] = useState(
    requirement.submission.Declination || null
  );
  const [immunizationDate, setImmunizationDate] = useState(
    requirement.submission.ImmunizationDate
      ? formatUTCMidnightToSameDay(
          requirement.submission.ImmunizationDate,
          "MM/dd/yyyy"
        )
      : ""
  );
  const [expirationDate, setExpirationDate] = useState(
    requirement.submission.ExpiresOn
      ? formatUTCMidnightToSameDay(
          requirement.submission.ExpiresOn,
          "MM/dd/yyyy"
        )
      : ""
  );
  const [requirementExpirationDate, setRequirementExpirationDate] = useState(
    requirement.expiresOn
      ? formatUTCMidnightToSameDay(requirement.expiresOn, "MM/dd/yyyy")
      : ""
  );
  const [isWaived, setIsWaived] = useState(requirement.isWaived);

  const [rejectionMessage, setRejectionMessage] = useState("");
  const [conditionalApprovalMessage, setConditionalApprovalMessage] = useState(
    requirement.approvalConditions
  );
  const rejectionMessageRef = useRef(null);
  const conditionalApprovalMessageRef = useRef(null);

  const [location, setLocation] = useState(
    requirement.submission.Location || ""
  );
  const [files, setFiles] = useState(requirement.submission.files || []);
  const [newFiles, setNewFiles] = useState([]);

  const [hasLicenses, setHasLicenses] = useState(
    requirement.hasLicenses ? "yes" : "no"
  );
  const [licenses, setLicenses] = useState(
    requirement.hasLicenses
      ? (requirement.submissions || []).map((license) => {
          return {
            id: license.Id,
            licenseType: license.LicenseType,
            licenseNumber: license.LicenseNumber,
            issuedBy: license.IssuedBy,
            issuedOn: license.IssuedOn
              ? formatUTCMidnightToSameDay(license.IssuedOn)
              : "",
            expiresOn: license.ExpiresOn
              ? formatUTCMidnightToSameDay(license.ExpiresOn)
              : "",
            files: (license.Files || []).map((a) => ({
              id: a.Id,
              name: a.Name,
              url: a.Url,
            })),
            newFiles: [],
          };
        })
      : []
  );

  const [viewResult, setViewResult] = useState(
    requirement.submission?.JSONContent
      ? JSON.parse(requirement.submission?.JSONContent)
      : {
          type: "",
          test1: {},
          test2: {},
          serie1: {},
          serie2: {},
        }
  );

  const handleChange = useCallback(
    (license, value, update) => {
      let name = update;

      const li = licenses.find((a) => a.id == license.id);
      li[name] = value;
      setLicenses([...licenses]);
    },
    [licenses]
  );

  const onAddMoreLicense = useCallback(() => {
    licenses.push({
      id: Math.random(),
      newFiles: [],
    });
    setLicenses([...licenses]);
  }, [licenses]);

  const licenseIndexForFilesRef = useRef(null);

  const onUploadLicenses = (index) => {
    licenseIndexForFilesRef.current = index;
    licenseFileRef.current.click();
  };

  const onLicenseFileChange = (e) => {
    const index = licenseIndexForFilesRef.current;
    const license = licenses[index];
    license.newFiles = e.target.files;
    setLicenses([...licenses]);
  };

  const onFileChange = (e) => {
    const addFiles = e.target.files;
    for (const f of addFiles) {
      if (f.name.length >= 255) {
        alert("Please use file name with less than 255 characters.");
        return;
      }
      f.id = Math.random();
    }
    setNewFiles((newFiles) => [...newFiles, ...addFiles]);
  };

  function removeFileFromFileList(fileList, indexToRemove) {
    const dt = new DataTransfer();

    for (let i = 0; i < fileList.length; i++) {
      if (i !== indexToRemove) {
        dt.items.add(fileList[i]);
      }
    }

    return dt.files;
  }

  const licenseFileRef = useRef(null);
  const fileRef = useRef(null);

  const onSetHasLicenses = useCallback(
    (value) => {
      if (value == "yes" && licenses.length == 0) {
        licenses.push({
          id: Math.random(),
          newFiles: [],
        });
        setLicenses([...licenses]);
      }

      setHasLicenses(value);
    },
    [hasLicenses, licenses]
  );

  const [approval, setApproval] = useState(requirement.status);

  useEffect(() => {
    setErrors([]);
  }, [approval]);

  const handleApprovalChange = (value) => {
    setApproval(value);
  };

  const [isLoading, setIsLoading] = useState(false);

  // const onSaveStudent = async () => {
  //   setIsLoading(true);
  //   try {
  //     const doesHaveLicenses = hasLicenses == "yes";
  //     const dto = {
  //       studentContactID,
  //       contactRequirementId: requirement.contactRequirementId,
  //       submissionId: requirement.submission.Id,
  //       licenses: doesHaveLicenses
  //         ? licenses.map((license) => {
  //           return {
  //             ...license,
  //             newFiles: Array.from(license.newFiles).map((f) => f.name),
  //           };
  //         })
  //         : [],
  //       declination: declination,
  //       immunizationDate,
  //       expirationDate,
  //       location,
  //       hasLicenses: doesHaveLicenses,
  //       files,
  //       newFiles: newFiles.map((a) => a.name),
  //       viewResult: JSON.stringify(viewResult),
  //       isWaived,
  //     };

  //     await submitRequirement(
  //       dto,
  //       [...newFiles].concat(
  //         licenses.flatMap((license) => {
  //           return Array.from(license.newFiles);
  //         })
  //       )
  //     ).then(() => {
  //       if (onSaveComplete) {
  //         onSaveComplete();
  //       }
  //     });

  //     console.log("UserInputView requirement", requirement);
  //     console.log("UserInputView OnSave dto", dto);
  //   } catch (error) {
  //     // Handle any errors here
  //     console.error("onSave failed:", error);
  //   } finally {
  //     // Set loading state to false, regardless of success or failure
  //     setIsLoading(false);
  //   }
  // };

  const insuranceViewRef = useRef();
  const tdapViewRef = useRef();
  const [validationErrors, setValidationErrors] = useState({});
  const handleValidation = (errors) => {
    setValidationErrors((prevErrors) => ({ ...prevErrors, ...errors }));
  };

  const validator = () => {
    if(requirement.byPassStudentInfoValidation){
      return true;
    }

    let newErrors = [];
    if (Categories.CERTS == requirement.type.Id) {
      if (!expirationDate || files.concat(newFiles).length === 0) {
        newErrors.push("Please provide an Expiration Date or upload a document.");
      }
    } else if (Categories.LICENSES == requirement.type.Id) {
    } else if (Categories.ELEARNING == requirement.type.Id) {
    } else if (Categories.FORMS == requirement.type.Id) {
      if (files.concat(newFiles).length === 0) {
        newErrors.push("Please upload a document.");
      }
    } else if (Categories.IMMUNIZATION == requirement.type.Id) {
      if (!requirement.subCategory) {
        if (!declination && files.concat(newFiles).length === 0) {
          newErrors.push(
            "Please select a declination option or upload a document."
          );
        }
      } else if (
        requirement.subCategory &&
        requirement.subCategory.Name == "Tdap"
      ) {
        const tdapViewErrors = tdapViewRef.current
          ? tdapViewRef.current.validate()
          : null;

        if (tdapViewErrors && Object.keys(tdapViewErrors).length > 0) {
          setValidationErrors(tdapViewErrors);
          newErrors.push("");
        } else if (
          files.concat(newFiles).length === 0 &&
          requirement.createdByTypeContact !== ContactTypes.CPNW_ADMIN
        ) {
          newErrors.push("Please upload a document.");
        } //#1568602332: CPNW QA BUGS
      } else {
        if (!viewResult.type && !declination) {
          newErrors.push(
            "Please select a submission option or a declination option."
          );
        }
      }
    } else if (Categories.INSURANCE == requirement.type.Id) {
      const insuranceViewErrors = insuranceViewRef.current
        ? insuranceViewRef.current.validate()
        : null;

      if (insuranceViewErrors && Object.keys(insuranceViewErrors).length > 0) {
        setValidationErrors(insuranceViewErrors);
        newErrors.push("");
      } else if (
        viewResult.type == SubmissionTypes.B &&
        files.concat(newFiles).length === 0
      ) {
        newErrors.push("Please upload a document.");
      }
    } else if (Categories.SCREENS_CHECKS == requirement.type.Id) {
      if (!declination && files.concat(newFiles).length === 0) {
        newErrors.push(
          "Please select a declination option or upload a document."
        );
      }
    } else if (Categories.SITE_ORIENTATIONS == requirement.type.Id) {
    }

    setErrors(newErrors);
    // If all checks pass, allow continuous
    return newErrors.length > 0 ? false : true;
  };

  const onSave = async () => {
    if (!roles.includes("CPNW-REVIEWER")) {
      validator();
    }
    // if (canEdit) {
    //   await onSaveStudent();
    // }
    if (!canReview) {
      setErrors(["You cannot review this requirement"]);
      return;
    }
    if (approval == "Approved") {
      if (expirationDateRequired && !requirementExpirationDate) {
        setErrors(["Must enter Requirement Expiration Date"]);
        return;
      }
    } else if (approval == "Rejected") {
      if (!rejectionMessage) {
        rejectionMessageRef.current.focus();
        setErrors(["Must enter Rejection Message when selecting Reject"]);
        return;
      }
    } else if (approval == "Conditionally Approved") {
      if (expirationDateRequired && !requirementExpirationDate) {
        setErrors(["Must enter Requirement Expiration Date"]);
        return;
      }
      if (!conditionalApprovalMessage) {
        conditionalApprovalMessageRef.current.focus();
        setErrors([
          "Must enter Conditions Message when selecting Conditionally Approved",
        ]);
        return;
      }
    } else {
      if (!roles.includes("CPNW-REVIEWER")) {
        setErrors(["Please select either Accept or Reject"]);
        return;
      }
    }

    const doesHaveLicenses = hasLicenses == "yes";
    const dto = {
      contactId: requirement.contactId,
      contactRequirementId: requirement.contactRequirementId,
      submissionId: requirement.submission.Id,
      licenses: doesHaveLicenses
        ? licenses.map((license) => {
            return {
              ...license,
              newFiles: Array.from(license.newFiles).map((f) => f.name),
            };
          })
        : [],
      declination: declination,
      immunizationDate,
      expirationDate,
      location,
      hasLicenses: doesHaveLicenses,
      files,
      newFiles: newFiles.map((a) => a.name),
      approval,
      rejectionMessage,
      conditionalApprovalMessage,
      viewResult: JSON.stringify(viewResult),
      isWaived,
    };

    if (approval == "Approved" || approval == "Conditionally Approved") {
      if (expirationDateRequired) {
        dto.requirementExpirationDate = requirementExpirationDate;
      }
    }

    setErrors([]);
    console.log("PassportReqiurementReviewerView requirement", requirement);
    console.log("PassportReqiurementReviewerView OnSave dto", dto);

    setIsLoading(true);

    postApproval(
      dto,
      [...newFiles].concat(
        licenses.flatMap((license) => {
          return Array.from(license.newFiles);
        })
      )
    ).then(() => {
      onSaveComplete();
      setIsLoading(false);
    });
  };

  return (
    <>
    <div className={classNames(styles.header, styles.stickyHeader)}>
          <h3>{studentInfo.lastName}, {studentInfo.firstName}</h3>
        </div>
      <div className={classNames(styles.container, styles.customContainer)}>
         
        <div className={classNames(styles.column, styles.column1)}>
          <div className={styles.row}>
            <PassportReviewItem
              label="Requirement Name"
              description={requirement.requirementName}
            />
            <PassportReviewItem
              label="Abbr"
              description={requirement.abbreviation}
            />
            <PassportReviewItem
              label="Required By"
              description={
                Array.isArray(requirement.requiredBy)
                  ? requirement.requiredBy[0]
                  : requirement.requiredBy
              }
            />
            <PassportReviewItem
              label="Frequency"
              description={requirement.frequency}
            />
          </div>
          {showRequirementInstructions && (
            <div>
              <div className={styles.instructions}>
                <PassportReviewItem
                  label="Instructions"
                  description={requirement.instructions}
                />
              </div>
              <div className={styles.row}>
                <div className={styles.column}>
                  <p className={styles.paragraph}>Files/Forms</p>
                  <div className={styles.fileSetup}>
                    {requirement.requirementFiles.map((f) => {
                      return <PassportDownloadItem file={f} />;
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}

          <hr>{/* Horizontal Rule */}</hr>
          <h4>Submission:</h4>
          {requirement.allowWaiver && (
            <div className={styles.column}>
              <CheckboxItem
                className={styles.checkbox}
                onClick={(isChecked) => setIsWaived(!!isChecked)}
                description={"Waived"}
                isChecked={isWaived}
                disabled={!canEdit}
              />
            </div>
          )}
          {!isWaived && (
            <div>
              {Categories.IMMUNIZATION == requirement.type.Id &&
                !requirement.subCategory && (
                  <div
                    className={classNames(
                      styles.row,
                      styles.columnImmunizationDate
                    )}
                  >
                    <FormDatePicker
                      label="Immunization Date"
                      dateFormat="MM/dd/yyyy"
                      dateString={immunizationDate}
                      setDate={setImmunizationDate}
                      disabled={!canEdit}
                    />
                    {/* <div
                    className={classNames(styles.column, styles.columnLocation)}
                  >
                    <FormDatePicker
                      label="Expiration Date"
                      dateFormat="MM/dd/yyyy"
                      dateString={expirationDate}
                      setDate={setExpirationDate}
                      disabled={!canEdit}
                    />
                  </div> */}
                    <div className={styles.column}>
                      <InputBox
                        label="Location Received"
                        placeholder="Provider Name & Location"
                        value={location}
                        setValue={(e) => {
                          setLocation(e.target.value);
                        }}
                        disabled={!canEdit}
                      />
                    </div>
                  </div>
                )}
              {requirement.subCategory &&
                requirement.subCategory.Name == "TB" && (
                  <ImmunizationTBView
                    data={viewResult}
                    disabled={!canEdit}
                    onChange={(result) => {
                      console.log("ImmunizationTBView result", result);
                      setViewResult(result);
                    }}
                  />
                )}
              {requirement.subCategory &&
                requirement.subCategory.Name == "HepB" && (
                  <ImmunizationHepBView
                    data={viewResult}
                    disabled={!canEdit}
                    onChange={(result) => {
                      console.log("ImmunizationHepBView result", result);
                      setViewResult(result);
                    }}
                  />
                )}
              {requirement.subCategory &&
                requirement.subCategory.Name == "MMR/MMRV" && (
                  <ImmunizationMMRView
                    data={viewResult}
                    disabled={!canEdit}
                    onChange={(result) => {
                      console.log("ImmunizationMMRView result", result);
                      setViewResult(result);
                    }}
                  />
                )}
              {requirement.subCategory &&
                requirement.subCategory.Name == "Varicella" && (
                  <ImmunizationVaricellaView
                    data={viewResult}
                    disabled={!canEdit}
                    onChange={(result) => {
                      console.log("ImmunizationVaricellaView result", result);
                      setViewResult(result);
                    }}
                  />
                )}
              {requirement.subCategory &&
                requirement.subCategory.Name == "Tdap" && (
                  <ImmunizationTdapView
                    data={viewResult}
                    disabled={!canEdit}
                    onChange={(result) => {
                      console.log("ImmunizationTdapView result", result);
                      setViewResult(result);
                    }}
                    ref={tdapViewRef}
                    onValidate={handleValidation}
                  />
                )}
              {requirement.subCategory &&
                requirement.subCategory.Name == "Covid-19" && (
                  <ImmunizationCovidView
                    data={viewResult}
                    disabled={!canEdit}
                    onChange={(result) => {
                      console.log("ImmunizationCovidView result", result);
                      setViewResult(result);
                    }}
                  />
                )}
              {requirement.subCategory &&
                requirement.subCategory.Name == "Respirator" && (
                  <ScreenRespiratorView
                    data={viewResult}
                    disabled={!canEdit}
                    onChange={(result) => {
                      console.log("ImmunizationRespiratorView result", result);
                      setViewResult(result);
                    }}
                  />
                )}
              {requirement.type.Name == "Insurance" && (
                <InsuranceView
                  data={viewResult}
                  disabled={!canEdit}
                  onChange={(result) => {
                    console.log("InsuranceView result", result);
                    setViewResult(result);
                    setErrors([]);
                  }}
                  ref={insuranceViewRef}
                  onValidate={handleValidation}
                />
              )}
              {(requirement.type.HasImmunizationDate ||
                requirement.type.HasLocation ||
                requirement.type.HasExpiration) && (
                <div className={styles.row}>
                  {requirement.type.HasImmunizationDate && (
                    <FormDatePicker
                      label="Immunization Date"
                      dateFormat="MM/dd/yyyy"
                      dateString={immunizationDate}
                      setDate={setImmunizationDate}
                      disabled={!canEdit}
                    />
                  )}
                  {requirement.type.HasExpiration && (
                    <FormDatePicker
                      label="Expiration Date"
                      dateFormat="MM/dd/yyyy"
                      dateString={expirationDate}
                      setDate={setExpirationDate}
                      disabled={!canEdit}
                    />
                  )}
                  {requirement.type.HasLocation && (
                    <div className={styles.column}>
                      <InputBox
                        label="Location Received"
                        placeholder="Provider Name & Location"
                        value={location}
                        setValue={(e) => {
                          setLocation(e.target.value);
                        }}
                        disabled={!canEdit}
                      />
                    </div>
                  )}
                </div>
              )}

              {requirement.type.HasLicense ? (
                <>
                  <div className={styles.innerColumnContainer}>
                    <div className={styles.innerColumn}>
                      <span className={styles.textBold}>
                        Do you hold an active healthcare license?
                      </span>
                      <QuestionToggle
                        disabled={!canEdit}
                        value={hasLicenses}
                        onClick={(value) => onSetHasLicenses(value)}
                      />
                    </div>
                  </div>
                  <div>
                    {licenses.length > 0 && (
                      <div className={styles.licenseContainer}>
                        <div className={styles.innerColumnContainer}>
                          <div className={styles.innerColumn}>
                            <span className={styles.textBold}>
                              Please enter your license information and attach
                              copies.
                              <br />
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    {licenses.map((license, index) => {
                      return (
                        <div className={styles.licenseContainer}>
                          <div
                            className={classNames(styles.innerColumnContainer)}
                          >
                            <div className={classNames(styles.innerColumn)}>
                              <InputBox
                                label={"License Type"}
                                placeholder={"License Type"}
                                setValue={(e) =>
                                  handleChange(
                                    license,
                                    e.target.value,
                                    "licenseType"
                                  )
                                }
                                value={license.licenseType}
                                disabled={!canEdit}
                              />
                            </div>
                            <div className={classNames(styles.innerColumn)}>
                              <SelectBox
                                label={"State"}
                                onChange={(value) => {
                                  handleChange(license, value, "issuedBy");
                                }}
                                placeholder={"Select"}
                                options={states}
                                selected={license.issuedBy}
                                disabled={!canEdit}
                              />
                              {/* <InputBox
                            label={"Issued By"}
                            placeholder={"Issued By"}
                            setValue={(e) =>
                              handleChange(license, e.target.value, "issuedBy")
                            }
                            value={license.issuedBy}
                            disabled={!canReview}
                          /> */}
                            </div>
                            <div className={classNames(styles.innerColumn)}>
                              <InputBox
                                label={"License #"}
                                placeholder={"License #"}
                                setValue={(e) =>
                                  handleChange(
                                    license,
                                    e.target.value,
                                    "licenseNumber"
                                  )
                                }
                                value={license.licenseNumber}
                                disabled={!canEdit}
                              />
                            </div>
                            {/* <div className={classNames(styles.innerColumn)}>
                              <FormDatePicker
                                label={"Issued On"}
                                placeholder={"Issued On"}
                                setDate={(value) => {
                                  handleChange(license, value, "issuedOn");
                                }}
                                dateString={license.issuedOn}
                                dateFormat="MM/dd/yyyy"
                                disabled={!canReview}
                              />
                            </div> */}
                            <div className={classNames(styles.innerColumn)}>
                              <FormDatePicker
                                label={"Expiration Date"}
                                placeholder={"Expiration Date"}
                                setDate={(value) => {
                                  handleChange(license, value, "expiresOn");
                                }}
                                dateString={license.expiresOn}
                                dateFormat="MM/dd/yyyy"
                                disabled={!canEdit}
                              />
                            </div>
                          </div>
                          <div className={styles.innerColumnContainer}>
                            <div className={styles.innerColumn}>
                              <div className={styles.upload}>
                                {canEdit && (
                                  <AddButton
                                    onClick={() => onUploadLicenses(index)}
                                    label={"Add Files"}
                                    className={classNames(
                                      styles.addFileIcon,
                                      styles.actionIcons
                                    )}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            className={classNames(styles.innerColumnContainer)}
                          >
                            <div
                              className={classNames(
                                styles.innerColumn,
                                styles.actions
                              )}
                            >
                              {license.files && license.files.length > 0
                                ? license.files.map((f, fi) => (
                                    <DashboardPassportFileEditorItem
                                      key={fi}
                                      name={f.name}
                                      onView={() => {
                                        window.open(f.url, "_blank");
                                      }}
                                      onDelete={() => {
                                        license.files = license.files.filter(
                                          (a) => a.Id != f.Id
                                        );
                                        setLicenses([...licenses]);
                                      }}
                                      canDelete={canEdit}
                                    />
                                  ))
                                : null}
                              {license.newFiles && license.newFiles.length > 0
                                ? Array.from(license.newFiles).map((f, fi) => (
                                    <DashboardPassportFileEditorItem
                                      key={fi}
                                      name={f.name}
                                      onView={() => {
                                        window.open(
                                          URL.createObjectURL(f),
                                          "_blank"
                                        );
                                      }}
                                      onDelete={() => {
                                        license.newFiles =
                                          removeFileFromFileList(
                                            license.newFiles,
                                            fi
                                          );
                                        setLicenses([...licenses]);
                                      }}
                                      canDelete={canEdit}
                                    />
                                  ))
                                : null}
                            </div>
                          </div>
                          <hr />
                        </div>
                      );
                    })}

                    <input
                      type="file"
                      multiple={true}
                      onChange={(e) => onLicenseFileChange(e)}
                      ref={licenseFileRef}
                      style={{ display: "none" }}
                    />

                    {licenses.length > 0 && canEdit ? (
                      <AddButton
                        onClick={onAddMoreLicense}
                        label={"Add Another License"}
                        className={classNames(
                          styles.addFileIcon,
                          styles.actionIcons
                        )}
                      />
                    ) : null}
                  </div>
                </>
              ) : null}
            </div>
          )}

          {requirement.type.HasDeclination && (
            <div className={styles.column}>
              <p className={styles.paragraph}>Declination Reason</p>
              <div className={styles.row}>
                <DashboardRadio
                  isSelected={declination === DeclinationEnum.EggAllergy}
                  onChange={handleDeclinationChange}
                  value={DeclinationEnum.EggAllergy}
                  label={DeclinationEnum.EggAllergy}
                  disabled={!canEdit}
                />
                <DashboardRadio
                  isSelected={declination === DeclinationEnum.IngredientAllergy}
                  onChange={handleDeclinationChange}
                  value={DeclinationEnum.IngredientAllergy}
                  label={DeclinationEnum.IngredientAllergy}
                  disabled={!canEdit}
                />
                <DashboardRadio
                  isSelected={declination === DeclinationEnum.GuillainBarre}
                  onChange={handleDeclinationChange}
                  value={DeclinationEnum.GuillainBarre}
                  label={DeclinationEnum.GuillainBarre}
                  disabled={!canEdit}
                />
                <DashboardRadio
                  isSelected={declination === DeclinationEnum.Other}
                  onChange={handleDeclinationChange}
                  value={DeclinationEnum.Other}
                  label={DeclinationEnum.Other}
                  disabled={!canEdit}
                />
              </div>
            </div>
          )}

          {requirement.type.HasDocumentation && (
            <>
              <div className={styles.uploadRow}>
                <p className={styles.paragraph}>Upload Documentation</p>
                {canEdit && (
                  <AddButton
                    label="Add File"
                    onClick={() => {
                      fileRef.current.click();
                    }}
                  />
                )}
                <input
                  type="file"
                  multiple={true}
                  onChange={(e) => onFileChange(e)}
                  ref={fileRef}
                  style={{ display: "none" }}
                />
              </div>
              <div className={styles.fileSetup}>
                {files.map((f, idx) => (
                  <DashboardPassportFileEditorItem
                    key={idx}
                    name={f.name}
                    onView={() => {
                      console.log("onView ", f);
                      window.open(f.url, "_blank");
                    }}
                    onDelete={() => {
                      console.log("onDelete ", f);
                      if (canDeleteFile()) {
                        const confirmation = window.confirm("Please ensure the approval status aligns with document deletion. Are you sure you want to delete this document?");
                        if (confirmation) {
                          setFiles((files) =>
                            files.filter((file) => file.id !== f.id)
                          );
                        }
                      }
                    }}
                    canDelete={canEdit && canDeleteFile()}
                  />
                ))}
                {Array.from(newFiles).map((f, idx) => (
                  <DashboardPassportFileEditorItem
                    key={idx}
                    name={f.name}
                    onView={() => {
                      console.log("onView ", f);
                      window.open(URL.createObjectURL(f), "_blank");
                    }}
                    onDelete={() => {
                      console.log("onDelete ", f);
                      if (canDeleteFile()) {
                        const confirmation = window.confirm(
                          "Are you sure you want to delete this document?"
                        );
                        if (confirmation) {
                          setNewFiles((files) =>
                            files.filter((file) => file.id != f.id)
                          );
                        }
                      }
                    }}
                    canDelete={canEdit}
                  />
                ))}
              </div>
            </>
          )}
          <div className={styles.row}>
            <div className={styles.column}>
              <p className={styles.paragraph}>Approval</p>
              <div className={styles.row}>
                <DashboardRadio
                  isSelected={approval === "Approved"}
                  onChange={handleApprovalChange}
                  value="Approved"
                  label="Accept"
                  disabled={!canReview}
                />
                <DashboardRadio
                  isSelected={approval === "Conditionally Approved"}
                  onChange={handleApprovalChange}
                  value="Conditionally Approved"
                  label="Conditionally Approved"
                  disabled={!canReview}
                />
                <DashboardRadio
                  isSelected={approval === "Rejected"}
                  onChange={handleApprovalChange}
                  value="Rejected"
                  label="Reject"
                  disabled={!canReview}
                />
              </div>
              <div className={styles.row}>
                {approval == "Approved" && expirationDateRequired && (
                  //Expiration Date
                  <FormDatePicker
                    label={"Requirement Expiration Date"}
                    placeholder={"MM/DD/YYYY"}
                    setDate={(value) => {
                      setRequirementExpirationDate(value);
                    }}
                    dateString={requirementExpirationDate}
                    dateFormat="MM/dd/yyyy"
                    disabled={!canReview}
                  />
                )}
                {approval == "Rejected" && (
                  <>
                    <label htmlFor="messageContent">Why is it rejected?</label>
                    <textarea
                      name="messageContent"
                      id="messageContent"
                      ref={rejectionMessageRef}
                      className={styles.text_area}
                      onChange={(e) => setRejectionMessage(e.target.value)}
                      disabled={!canReview}
                    ></textarea>
                  </>
                )}
                {approval == "Conditionally Approved" && (
                  <div>
                    <div>
                      {/* //Expiration Date */}
                      {expirationDateRequired && (
                        <FormDatePicker
                          label={"Requirement Expiration Date"}
                          placeholder={"MM/DD/YYYY"}
                          setDate={(value) => {
                            setRequirementExpirationDate(value);
                          }}
                          dateString={requirementExpirationDate}
                          dateFormat="MM/dd/yyyy"
                          disabled={!canReview}
                        />
                      )}
                    </div>
                    <div>
                      <label htmlFor="messageContent">
                        What are the conditions?
                      </label>
                      <textarea
                        name="messageContent"
                        id="messageContent"
                        ref={conditionalApprovalMessageRef}
                        className={styles.text_area}
                        onChange={(e) =>
                          setConditionalApprovalMessage(e.target.value)
                        }
                        disabled={!canReview}
                      ></textarea>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {errors.length > 0 && (
            <ul>
              {errors.map((error) => {
                return error && <li className={styles.error}>{error}</li>;
              })}
            </ul>
          )}

          {(canEdit || canReview) && (
            <Button
              className={styles.custom_button}
              onClick={onSave}
              text="SAVE"
            />
          )}

          <div className={styles.error}>
            {!canReview
              ? "You don't have permissions to review this requirement!"
              : null}
          </div>
        </div>

        <div className={classNames(styles.borderRight, styles.column2)}></div>
        <div className={classNames(styles.column, styles.column3)}>
          <h3>Submission Status:</h3>
          <h3>{requirement.status}</h3>

          <hr>{/* Horizontal Rule */}</hr>
          <PassportMessageForm messages={messages} onSend={handleOnSend} />
          <hr>{/* Horizontal Rule */}</hr>
        </div>

        {isLoading && (
          <div className={styles.loading}>
            <Loading />
          </div>
        )}
      </div>
    </>
  );
}

PassportRequirementReviewerView.propTypes = {};
