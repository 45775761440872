import React, { useEffect } from "react";
import environment from "environment";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import FreshDeskWidget from "widgets/FreshDeskWidget";
import { StaticRoutes } from "pages/routes";
import Layout from "pages/Layout";
import TakeQuizPage from "pages/TakeQuizPage";
import ClinicalPassport from "pages/DashboardClinicalPrerequisites/pages/DashboardClinicalPassport"
import SiteRequirements from "pages/DashboardClinicalPrerequisites/pages/SiteRequirements";
import ELearning from "pages/DashboardClinicalPrerequisites/pages/eLearning";
import ELearningResults from "pages/DashboardMyRecords/pages/eLearningResults";
import OIGSAMPage from "pages/DashboardMyRecords/pages/OIGSAMPage";
import MyProfile from "pages/DashboardMyAccountPage/pages/DashboardMyProfile";
import MySecuritySettings from "pages/DashboardMyAccountPage/pages/MySecuritySettings";
import DashboardMyDemographics from "pages/DashboardMyAccountPage/pages/DashboardMyDemographics";
import MyDashboardPage from "pages/MyDashboardPage";
import Assignments from "pages/DashboardAdminPage/pages/Assignments";
import Reports from "pages/DashboardAdminPage/pages/Reports";
import Users from "pages/DashboardAdminPage/pages/Users";
import DocumentsPage from "pages/DocumentsPage";
import HomePage from "pages/HomePage";
import ConsentPage from "pages/ConsentPage";
import { AccountContainer } from "hooks/useAccount";
import SelfPayPage from "pages/SelfPayPage";
import RequirementsPage from "pages/PassportPages/AdminPages/RequirementsPage";
import RequirementFormPage from "pages/PassportPages/AdminPages/RequirementFormPage";
import ReviewPage from "pages/PassportPages/ReviewerPages/ReviewPage";
import HealthcareReviewPage from "pages/PassportPages/ReviewerPages/HealthcareReviewPage/HealthcareReviewPage";
import CPNWReviewerPage from "pages/PassportPages/ReviewerPages/CPNWReviewerPage";
import MyRequirementsPage from "pages/PassportPages/UserPages/MyRequirementsPage";
import ImpersonateUser from "pages/DashboardAdminPage/pages/ImpersonateUser";
import BackgroundWatch from "pages/DashboardAdminPage/pages/BackgroundWatch";

const NotFoundRedirect = () => {
  const location = useLocation();

  useEffect(() => {
    const externalBaseUrl = environment.homeUrl;
    const targetUrl = `${externalBaseUrl}${location.pathname}${location.search}${location.hash}`;
    window.location.href = targetUrl;
  }, [location]);

  return (
    <></>
  );
};

function App() {
  return (
    <>
      <FreshDeskWidget />
      <Router>
        <Switch>
          <Route exact path="/">
            <Layout>
              <MyDashboardPage />
            </Layout>
          </Route>
          <Route path="/trainingmodules/*">
            <TakeQuizPage />
          </Route>
          <Route path="/Documents/*">
            <Layout>
              <DocumentsPage />
            </Layout>
          </Route>
          <Route>
            <Layout>
              <Switch>
                <Route path={StaticRoutes.AccountConsent}>
                  <ConsentPage />
                </Route>
                <Route path={StaticRoutes.AccountSelfPay}>
                  <SelfPayPage />
                </Route>
                <Route
                  path={StaticRoutes.AccountRegistrationProfile}
                  component={MyProfile}
                />
                <Route
                  path={StaticRoutes.Passport}
                  component={ClinicalPassport}
                />
                <Route
                  path={StaticRoutes.SiteRequirements}
                  component={SiteRequirements}
                />
                <Route path={StaticRoutes.eLearning} component={ELearning} />
                <Route
                  path={StaticRoutes.eLearningResults}
                  component={ELearningResults}
                />
                <Route path={StaticRoutes.OIGSAM} component={OIGSAMPage} />
                <Route
                  path={StaticRoutes.MySecuritySettings}
                  component={MySecuritySettings}
                />
                <Route
                  path={StaticRoutes.MyDemographics}
                  component={DashboardMyDemographics}
                />
                <Route path={StaticRoutes.MyProfile} component={MyProfile} />

                <Route
                  path={StaticRoutes.AdminAssignments}
                  component={Assignments}
                />
                <Route path={StaticRoutes.AdminReports} component={Reports} />
                <Route path={StaticRoutes.AdminUsers} component={Users} />
                <Route path={StaticRoutes.AdminBackgroundWatch} component={BackgroundWatch} />

                <Route
                  path={StaticRoutes.Requirements}
                  component={RequirementsPage}
                />
                <Route
                  path={StaticRoutes.RequirementForm}
                  component={RequirementFormPage}
                />
                <Route path={StaticRoutes.AdminReview} component={ReviewPage} />
                <Route path={StaticRoutes.AdminHealthcareReview} component={HealthcareReviewPage} />
                <Route
                  path={StaticRoutes.AdminReviewer}
                  component={CPNWReviewerPage}
                />
                <Route
                  path={StaticRoutes.MyRecordsRequirements}
                  component={MyRequirementsPage}
                />
                <Route
                  path={StaticRoutes.ImpersonateUser}
                  component={ImpersonateUser}
                />

                <Route component={NotFoundRedirect} />
              </Switch>
            </Layout>
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
