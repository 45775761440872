import { useState, useRef, useEffect } from "react";
import styles from "./RequiredByDropdownED.module.css"; // Ensure your CSS file is correctly included
import { useMemo } from "react";
import { TipBox } from "@cpnw/ui";

const RequiredByDropdownED = ({ placeholder, options, tip, selected, onSelect }) => {
    const [open, setOpen] = useState(false);
    const [selectedPrograms, setSelectedPrograms] = useState([]);
    const dropdownRef = useRef(null);
    const [tipHovered, setTipHovered] = useState(false);

    const transformData = (data) => {
        const schoolMap = new Map();

        data.forEach(({ schoolId, schoolName, programId, programName }) => {
            if (!schoolMap.has(schoolId)) {
                schoolMap.set(schoolId, {
                    id: `${schoolId}`,
                    name: schoolName,
                    programs: []
                });
            }
            schoolMap.get(schoolId).programs.push({
                id: `${programId}`,
                name: programName
            });
        });

        return Array.from(schoolMap.values())
            .sort((a, b) => a.name.localeCompare(b.name)) // Sort schools alphabetically
            .map(school => ({
                ...school,
                programs: school.programs.sort((a, b) => a.name.localeCompare(b.name)) // Sort programs alphabetically
            }));
    }

    const schoolsAndPrograms = useMemo(() => transformData(options), [options]);

    const findMatchingOptions = (data, options) => {
        const dataIds = new Set(data.map(item => String(item.Id))); // Convert to Set for efficient lookup

        return options
            .map(school => {
                const matchedPrograms = school.programs.filter(program => dataIds.has(program.id));

                return matchedPrograms.length > 0 ? { ...school, programs: matchedPrograms } : null;
            })
            .filter(Boolean); // Remove null values
    };

    const selectedOptions = useMemo(() =>  findMatchingOptions(selected, schoolsAndPrograms), selected);

    // Toggle selection for a sub-item (program)
    const handleSubChange = (e, schoolId, programId) => {
        e.stopPropagation();

        setSelectedPrograms((prevSelected) => {
            return {
                ...prevSelected,
                [schoolId]: {
                    ...prevSelected[schoolId],
                    [programId]: !prevSelected[schoolId]?.[programId] // Toggle selection
                }
            };
        });

    };

    // Toggle selection for a parent item (school)
    const handleParentChange = (e, schoolId) => {
        e.stopPropagation();

        setSelectedPrograms((prevSelected) => {
            const allPrograms = schoolsAndPrograms.find(s => s.id === schoolId)?.programs || [];
            const isSelected = prevSelected[schoolId] && Object.keys(prevSelected[schoolId]).length > 0;

            if (isSelected) {
                const updatedSelection = { ...prevSelected };
                delete updatedSelection[schoolId]; // Unselect all programs
                return updatedSelection;
            } else {
                return {
                    ...prevSelected,
                    [schoolId]: Object.fromEntries(allPrograms.map(p => [p.id, true])) // Select all
                };
            }
        });
    };

    const getSelectedData = () => {
        const selectedData = [];

        Object.entries(selectedPrograms).forEach(([schoolId, programs]) => {
            if (Object.keys(programs).length > 0) {
                const school = schoolsAndPrograms.find(s => s.id === schoolId);
                school.programs.forEach(p => {
                    if (programs[p.id]) {
                        selectedData.push({
                            // schoolId: school.id,
                            // schoolName: school.name,
                            Id: Number(p.id),
                            Name: p.name
                        });
                    }
                });
            }
        });
        return selectedData;
    };

    const setSelectedData = (selectedArray) => {
        const newSelectedPrograms = {};

        selectedArray.forEach((school) => {
            newSelectedPrograms[school.id] = {};
            school.programs.forEach((program) => {
                newSelectedPrograms[school.id][program.id] = true;
            });
        });

        setSelectedPrograms(newSelectedPrograms);
    };

    const getSelectedCount = (selectedPrograms) => {
        return Object.values(selectedPrograms).reduce(
            (count, programs) => count + Object.values(programs).filter(val => val === true).length,
            0
        );
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setOpen(false);
            }
        };
        document.addEventListener("click", handleClickOutside);
        return () => document.removeEventListener("click", handleClickOutside);
    }, []);

    useEffect(() => {
        onSelect(getSelectedData());
    }, [selectedPrograms]);

    useEffect(() => {
        if (schoolsAndPrograms.length > 0) {
            setSelectedData(selectedOptions);
        }
    }, [schoolsAndPrograms]);

    // Count selected programs
    const selectedCount = useMemo(() => getSelectedCount(selectedPrograms), [selectedPrograms]);

    return (
        <>
            <div className={styles.container} ref={dropdownRef}>
                <div className={`${styles.selectBtn} ${open ? styles.open : ""}`} onClick={() => setOpen(!open)}
                    onMouseEnter={() => setTipHovered(true)}
                    onMouseLeave={() => setTipHovered(false)}
                >
                    <span className={styles.btnText}>{selectedCount > 0 ? `${selectedCount} Programs Selected` : placeholder}</span>
                </div>
                {open && (
                    <ul className={styles.listItems}>
                        {schoolsAndPrograms.map((school) => {
                            const isParentChecked = selectedPrograms[school.id] && Object.keys(selectedPrograms[school.id]).length > 0;

                            return (
                                <li key={school.id} className={styles.item}>
                                    <label>
                                        <input
                                            name={school.id}
                                            type="checkbox"
                                            checked={!!isParentChecked}
                                            onChange={(e) => handleParentChange(e, school.id)}
                                        />
                                        {school.name}
                                    </label>
                                    <ul className={styles.subItems}>
                                        {school.programs.map((program) => (
                                            <li key={program.id} className={styles.subItem}>
                                                <label>
                                                    <input
                                                        name={program.id}
                                                        type="checkbox"
                                                        checked={!!selectedPrograms[school.id]?.[program.id] || false}
                                                        onChange={(e) => handleSubChange(e, school.id, program.id)}
                                                    />
                                                    {program.name}
                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            );
                        })}
                    </ul>
                )}
            </div>
            {tipHovered ? <div className={styles.tipContainer}>
                <TipBox tip={tip} className={styles.customTip} />
            </div> : ""}
        </>
    );
};

export default RequiredByDropdownED;
