import React, { useMemo, useEffect, useState } from "react";
import environment from "environment";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Auth } from "../../auth";
import { StaticRoutes } from "pages/routes";
import { AdSpaceButton, Footer, NavMenu } from "@cpnw/ui";
import { AccountContainer } from "hooks/useAccount";
import { CpnwContainer } from "hooks/useCpnw";
import { AdminContainer } from "hooks/useAdmin";
import DashboardNavDropdown from "components/DashboardNavDropdown";

import styles from "./Layout.module.css";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import classNames from "classnames";
import { CanPermissions, UserRegState } from "pages/enums";
import { RequirementContainer } from "hooks/useRequirement";
import { result } from "lodash-es";

const PageLockRedirect = ({ children }) => {
  const { meData, canAdmin, hasCanPermissions, userRoles } = AccountContainer.useContainer();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    if (meData) {
      if (meData.regState == UserRegState.AwaitingEmailConfirmation) {
        window.location.href = environment.homeUrl + "/Account/ConfirmEmail";
      } else if (
        meData.regState == UserRegState.AwaitingConsent &&
        path != StaticRoutes.AccountConsent
      ) {
        history.push(StaticRoutes.AccountConsent);
      } else if (
        !canAdmin &&
        (path == StaticRoutes.AdminReports ||
          path == StaticRoutes.AdminUsers ||
          path == StaticRoutes.AdminAssignments)
      ) {
        history.push(StaticRoutes.MyDashboard);
      }
      else if (
        path == StaticRoutes.AdminReview && !(hasCanPermissions(CanPermissions.CanCoordinate) 
        && (userRoles.includes("EDUCATION") || userRoles.includes("FACULTY")))
      ) {
        history.push(StaticRoutes.MyDashboard);
      } 
      else if (
        path == StaticRoutes.AdminReviewer && !(hasCanPermissions(CanPermissions.CanCoordinate) 
        && userRoles.includes("CPNW-REVIEWER"))
      ) {
        history.push(StaticRoutes.MyDashboard);
      } 
      else if (
        path == StaticRoutes.AdminHealthcareReview && !(userRoles.includes("HEALTHCARE"))
      ) {
        history.push(StaticRoutes.MyDashboard);
      } 
      else if (
        (meData.regState == UserRegState.AwaitingBioCompletion ||
          meData.regState == UserRegState.FixBio) &&
        path != StaticRoutes.AccountRegistrationProfile
      ) {
        history.push(StaticRoutes.AccountRegistrationProfile);
      } else if (meData.regState == UserRegState.PendingApproval) {
        window.location.href = environment.homeUrl;
      } else if (meData.regState == UserRegState.Archived) {
        window.location.href = environment.homeUrl + "/Account/Archived";
      } else if (meData.regState == UserRegState.Approved) {
        return;
      } else if (meData.regState == UserRegState.AwaitingSelfPay) {
        if (path != StaticRoutes.AccountSelfPay) {
          history.push(StaticRoutes.AccountSelfPay);
        }
      } else if (meData.regState == UserRegState.Rejected) {
        window.location.href = environment.homeUrl;
      } else if (meData.regState == UserRegState.Inactive) {
        window.location.href = environment.homeUrl;
      }
    }
  }, [location, meData, canAdmin]);

  return <>{children}</>;
};

export function Layout(props) {
  return (
    <Auth.Provider>
      <AccountContainer.Provider>
        <RequirementContainer.Provider>
          <CpnwContainer.Provider>
            <AdminContainer.Provider>
              <PageLockRedirect>
                <LayoutWrapper>{props}</LayoutWrapper>
              </PageLockRedirect>
            </AdminContainer.Provider>
          </CpnwContainer.Provider>
        </RequirementContainer.Provider>
      </AccountContainer.Provider>
    </Auth.Provider>
  );
}

function LayoutWrapper({ children }) {
  return <LayoutContent {...children} />;
}

function LayoutContent({ children }) {
  const history = useHistory();
  const { logout, userRoles, conAssignments, canAdmin, hasCanPermissions } =
    AccountContainer.useContainer();

  const isAccountRegistrationProfile =
    window.location.pathname === StaticRoutes.AccountRegistrationProfile;
  const isConsent =
    window.location.pathname?.toLowerCase() ===
    StaticRoutes.AccountConsent.toLowerCase();
  const isSelfPay =
    window.location.pathname?.toLowerCase() ===
    StaticRoutes.AccountSelfPay.toLowerCase();

  const isFullscreenPage =
    isAccountRegistrationProfile || isConsent || isSelfPay;

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const openUrl = (url) => {
    history.push(url);
  };

  const onLogout = () => {
    logout();
  };

  const navMenus = [
    {
      label: "Logout",
      onClick: (e) => {
        onLogout();
      },
    },

    {
      label: "My Dashboard",
      onClick: (e) => {
        openUrl(StaticRoutes.MyDashboard);
      },
    },
  ];

  const navDropdownItems = [
    {
      label: "About Us",
      onClick: (e) => {
        openUrl(StaticRoutes.AboutUs);
      },
      pageList: [
        {
          label: "Healthcare Partners",
          onClick: (e) => {
            openUrl(StaticRoutes.AboutUsHealthcarePartners);
          },
        },
        {
          label: "Participating Programs",
          onClick: (e) => {
            openUrl(StaticRoutes.AboutUsParticipatingPrograms);
          },
        },
        {
          label: "Nursing Placement",
          onClick: (e) => {
            openUrl(StaticRoutes.AboutUsNursingPlacement);
          },
        },
      ],
    },
    {
      label: "Our Services",
      onClick: (e) => {
        openUrl(StaticRoutes.OurServices);
      },
    },
    {
      label: "FAQ",
      onClick: (e) => {
        openUrl(StaticRoutes.FAQ);
      },
    },
    {
      label: "Get Started",
      onClick: (e) => {
        openUrl(StaticRoutes.GetStarted);
      },
      pageList: [
        {
          label: "Schools",
          onClick: (e) => {
            openUrl(StaticRoutes.GetStartedSchools);
          },
        },
        {
          label: "Students",
          onClick: (e) => {
            openUrl(StaticRoutes.GetStartedStudents);
          },
        },
        {
          label: "Healthcare",
          onClick: (e) => {
            openUrl(StaticRoutes.GetStartedHealthcare);
          },
        },
      ],
    },
  ];

  const formattedNavDropdownItems = navDropdownItems.map((item) => {
    let newItem = { ...item };
    delete newItem.pageList;
    return newItem;
  });

  const hamburgerItems = [...navMenus, ...formattedNavDropdownItems];

  const location = useLocation();
  const currentRoute = location.pathname;

  const [selectedContentNav, setSelectedContentNav] = useState(-1);

  const leftNavigationMenus = useMemo(() => {
    var results = JSON.parse(JSON.stringify(contentNavItems)); //deep clone

    const requirementsRoles = ["Healthcare", "Education", "CPNW-ADMIN"].map(
      (a) => a.toUpperCase()
    );
    const reviewRoles = ["Healthcare", "Education", "CPNW-REVIEWER"].map((a) =>
      a.toUpperCase()
    );
    const myRequirementsRoles = ["Faculty", "Student"].map((a) =>
      a.toUpperCase()
    );

    const roles = userRoles.map((a) => a.toUpperCase());

    if (!roles.some((a) => myRequirementsRoles.includes(a))) {
      //only show My Records of one of myRequirementsRoles
      const myRecord = results.find((a) => a.header == "My Records");
      myRecord.links = myRecord.links.filter((a) => a.name != "Requirements");

      // console.log("myRecord", myRecord);
      // console.log("contentNavItems", contentNavItems);
    }

    if (roles.some((a) => requirementsRoles.includes(a)) || (roles.includes("FACULTY") && hasCanPermissions(CanPermissions.CanCoordinate))) {
      //only show Requirements if one of the requirementsRoles
      if (hasCanPermissions(CanPermissions.CanCoordinate)) {
        results.unshift(requirementsMenu);
      }
    }

    if (canAdmin) {
      var filteredAdminMenu = JSON.parse(JSON.stringify(adminMenu));
      // Show AdminReviewerMenu for "CPNW-REVIEWER" role only
      if (roles.includes("CPNW-REVIEWER")) {
        filteredAdminMenu.links.push(AdminReviewerMenu);
      }

      if (roles.includes("HEALTHCARE")) {
        filteredAdminMenu.links.push(AdminHealthcareReviewMenu);
      }

      if ((roles.includes("EDUCATION") || roles.includes("FACULTY")) && hasCanPermissions(CanPermissions.CanCoordinate)) {
        filteredAdminMenu.links.push(AdminReviewMenu);
      }

      //if the users role is only healthcare (healthcare and healthcare only)
      if (
        (roles.includes("EDUCATION") || roles.includes("FACULTY")) &&
        hasCanPermissions(CanPermissions.CanAssign)
      ) {
        filteredAdminMenu.links.push(AdminAssignmentsMenu);
      }

      if (hasCanPermissions(CanPermissions.CanCoordinate)) {
        filteredAdminMenu.links.push(AdminReportMenu);
        filteredAdminMenu.links.push(AdminUsersMenu);
        //TODO: Add Background Watch Menu when API is ready
        // filteredAdminMenu.links.push(AdminBackgroundWatchMenu);
      }

      results = results.concat(filteredAdminMenu);
      console.log(results);
      if (conAssignments.find((a) => a.CanOrgDocs) != null) {
        results.push(canOrgDocMenu);
      }
    }

    const links = results.flatMap((a) => a.links);
    links.forEach((link) => {
      link.active = false;
    });
    const activeItem = links.find((a) => a.src == currentRoute);
    if (activeItem) {
      activeItem.active = true;
    }

    return results;
  }, [conAssignments, userRoles, currentRoute, canAdmin, hasCanPermissions]);

  return (
    <div className={styles.container}>
      <NavMenu
        isStatic={true}
        onClickLogo={(e) => (window.location.href = environment.homeUrl)}
        navItems={navMenus}
        navDropdownItems={navDropdownItems}
        hamburgerItems={hamburgerItems}
      />
      {!isFullscreenPage && (
        <div className={styles.banner}>
          <AdSpaceButton
            size={"large"}
            onClick={() =>
              openInNewTab(
                "https://cpnw.blob.core.windows.net/documents/AdRotator/AdRequestFlyer-Form.pdf"
              )
            }
          />
        </div>
      )}
      <div className={styles.contentContainer}>
        {!isFullscreenPage && (
          <div className={styles.contentNav}>
            {leftNavigationMenus.map((item, index) => (
              <DashboardNavDropdown
                key={item.header}
                className={styles.contentNavItem}
                header={item.header}
                links={item.links}
                onClick={() => {
                  if (item.links.length > 0) {
                    setSelectedContentNav(
                      index != selectedContentNav ? index : -1
                    );
                  } else {
                    history.push(item.src);
                  }
                }}
                droppedDown={
                  selectedContentNav != -1
                    ? selectedContentNav == index
                    : item.links.find((a) => a.active) != null
                }
              />
            ))}
          </div>
        )}
        <div className={styles.children}>{children}</div>
      </div>
      <Footer />
    </div>
  );
}

const contentNavItems = [
  // {
  //   header: "Clinical Prerequisites",
  //   links: [
  //     {
  //       name: "Clinical Passport",
  //       src: StaticRoutes.Passport,
  //       tip: "A fillable PDF",
  //     },
  //     {
  //       name: "Site Requirements",
  //       src: StaticRoutes.SiteRequirements,
  //       tip: "View additional requirements at your clinical site",
  //     },
  //     {
  //       name: "eLearning",
  //       src: StaticRoutes.eLearning,
  //       tip: "Complete eLearning Modules",
  //     },
  //   ],
  // },
  {
    header: "My Records",
    links: [
      {
        name: "Requirements",
        src: StaticRoutes.MyRecordsRequirements,
        tip: "",
      },
      {
        name: "eLearning Results",
        src: StaticRoutes.eLearningResults,
        tip: "View your personal eLearning results",
      },
      {
        name: "OIG/SAM Check",
        src: StaticRoutes.OIGSAM,
        tip: "View your personal OIG/SAM results",
      },
    ],
  },
  {
    header: "My Account",
    links: [
      {
        name: "My Profile",
        src: StaticRoutes.MyProfile,
        tip: "View or change your profile information",
      },
      {
        name: "My Security Settings",
        src: StaticRoutes.MySecuritySettings,
        tip: "Change your preferences and update your password",
      },
      {
        name: "My Demographics",
        src: StaticRoutes.MyDemographics,
        tip: "Add a photo ID and choose to include other demographics ",
      },
    ],
  },
];

const AdminReviewMenu = {
  name: "Review",
  src: StaticRoutes.AdminReview,
  tip: "",
};

const AdminHealthcareReviewMenu = {
  name: "Review",
  src: StaticRoutes.AdminHealthcareReview,
  tip: "",
};

const AdminReviewerMenu = {
  name: "Review",
  src: StaticRoutes.AdminReviewer,
  tip: "CPNW Reviewer Menu",
};

const AdminReportMenu = {
  name: "Reports",
  src: StaticRoutes.AdminReports,
  tip: "Upload student documents and run OIG/SAM reports",
};

const AdminAssignmentsMenu = {
  name: "Assignments",
  src: StaticRoutes.AdminAssignments,
  tip: "Assign students to a clinical facility",
};

const AdminUsersMenu = {
  name: "Users",
  src: StaticRoutes.AdminUsers,
  tip: "General account management, including Approving and test",
};
const AdminBackgroundWatchMenu = {
  name: "Background + WATCH Reports",
  src: StaticRoutes.AdminBackgroundWatch,
  tip: "Manage Background and WATCH Reports",
};

const adminMenu = {
  header: "Admin",
  links: [],
};

const canOrgDocMenu = {
  header: "Documents",
  links: [
    {
      name: "Annual Meeting",
      src: StaticRoutes.AnnualMeeting,
    },
    {
      name: "Board",
      src: StaticRoutes.Board,
    },
    {
      name: "Corporate",
      src: StaticRoutes.Corporate,
    },
    {
      name: "Financial",
      src: StaticRoutes.Financial,
    },
    {
      name: "East",
      src: StaticRoutes.East,
    },
    {
      name: "North",
      src: StaticRoutes.North,
    },
    {
      name: "South",
      src: StaticRoutes.South,
    },
  ],
};

const requirementsMenu = {
  header: "Requirements",
  src: StaticRoutes.Requirements,
  links: [],
};

export default Layout;
